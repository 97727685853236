import { ChestTracking } from 'src/app/services/tracking/chest-tracking';
import { Tracking } from 'src/app/services/tracking/tracking';
import { NonMethods, Optional, TypeHelper } from 'src/app/services/type-helper';
import { ItemId } from 'src/app/services/items/item-enums';
import { Kampf, KampfErgebnis } from 'src/app/services/kampf/kampf';
import { ItemReferenz } from 'src/app/services/items/item-referenz';
import { Statistik } from 'src/app/services/spieler/statistik';
import type { Item } from 'src/app/services/items/item';
import { GegnerListe } from 'src/app/data/gegner-liste';
import { ItemListeHelper } from 'src/app/services/items/item-liste.helper';

export class Spieler {
  public static readonly SAVEGAME_PREFIX: string = 'player_';
  public static readonly MainCharacterSaveName: string = 'activePlayer';
  private static _MainCharacter?: Spieler;
  public static initialesKampfErgebnis?: KampfErgebnis;

  /** Wie viel Erfahrung auf dem Weg zum nächsten Level hat der Spieler */
  public erfahrungUebrig: number = 0;

  /** Level des Spielers */
  public level: number = 1;
  public inventar: ItemReferenz[] = [];
  public ausruestung: ItemReferenz[] = [];

  public kartenNummer: number = 1;
  public feldNummer: number = 1;
  public maxPetOrbs: number = 1;

  public aktiveWoche: Date = TypeHelper.getDateForWeek(new Date());
  public aktivSeit: Date = new Date();

  public name: string = '';
  public krankheitsmodus: boolean = false;

  public activityTracking: Tracking[] = [];
  public chestTracking: ChestTracking = new ChestTracking();

  public statistik: Statistik[] = [];

  constructor(definition?: Optional<NonMethods<Spieler>>) {
    Object.assign(this, Spieler.cleanDefinition(definition));
    this.reinstanciateComplexMembers();
  }

  static {
    // Main Character einmal initial laden
    Spieler.initialesKampfErgebnis = Spieler.reloadMainCharacter();
  }

  public static MainCharacter(): Spieler | undefined {
    return Spieler._MainCharacter;
  }

  public static reloadMainCharacter(silent: boolean = false): KampfErgebnis {
    Spieler._MainCharacter = new Spieler();
    const ret: KampfErgebnis | Error = Spieler._MainCharacter.loadFromSave(
      Spieler.MainCharacterSaveName,
      silent
    );
    if (ret instanceof Error) {
      Spieler._MainCharacter = undefined;
      return new KampfErgebnis(0, 0, 0);
    }
    // Spieler sofort speichern, damit Kampf nicht immer wieder ausgeführt wird, wenn man es neulädt
    Spieler._MainCharacter.savePlayer(true);
    return ret;
  }

  public static getExpRequiredForLevelUp(startingLevel: number): number {
    return 11.9 * startingLevel ** 2 + 70 * startingLevel + 462;
  }

  public static cleanDefinition(
    definition?: Optional<NonMethods<Spieler>>
  ): Optional<NonMethods<Spieler>> | undefined {
    if (definition != null) {
      // entferne alte Eigenschaften
      definition = TypeHelper.removeUndefinedProperties<
        Optional<NonMethods<Spieler>>
      >({
        name: definition.name,
        activityTracking: definition.activityTracking,
        aktiveWoche: definition.aktiveWoche,
        aktivSeit: definition.aktivSeit,
        ausruestung: definition.ausruestung,
        chestTracking: definition.chestTracking,
        erfahrungUebrig: definition.erfahrungUebrig,
        feldNummer: definition.feldNummer,
        inventar: definition.inventar,
        kartenNummer: definition.kartenNummer,
        krankheitsmodus: definition.krankheitsmodus,
        level: definition.level,
        maxPetOrbs: definition.maxPetOrbs,
        statistik: definition.statistik,
      });
    }

    return definition;
  }

  /**
   * Erstellt alle Subtypen neu, damit eine Kopie dieses Spielers unabhängige Eigenschaften von einer anderen Kopie dieses Spielers hat.
   */
  private reinstanciateComplexMembers(): void {
    this.aktiveWoche = TypeHelper.getDateForWeek(new Date(this.aktiveWoche));
    this.aktivSeit = new Date(this.aktivSeit);

    this.inventar ??= [];
    this.ausruestung ??= [];
    this.activityTracking ??= [];

    for (let i = 0; i < this.inventar.length; i++) {
      this.inventar[i] = new ItemReferenz(this.inventar[i]);
    }

    for (let i = 0; i < this.ausruestung.length; i++) {
      this.ausruestung[i] = new ItemReferenz(this.ausruestung[i]);
    }

    if (this.activityTracking.length > 7) {
      this.activityTracking = this.activityTracking.slice(0, 7);
    }
    for (let i = 0; i < 7; i++) {
      this.activityTracking[i] = new Tracking(this.activityTracking[i], {
        date: TypeHelper.getDateForWeek(this.aktiveWoche, i),
      });
    }

    this.chestTracking = new ChestTracking(this.chestTracking);

    for (let i = 0; i < this.statistik.length; i++) {
      this.statistik[i] = new Statistik(this.statistik[i]);
    }

    // Werte konsistent halten, deshalb einfache Werte aktualisieren
    this.updateLevel();
    ItemListeHelper.restackInventory(
      this.inventar,
      this.getNoEquipFightingPoints()
    );
  }

  /** Bringt Spieler bis zum angegebenen Datum */
  private timeWarpToDate(targetDate: Date = new Date()): KampfErgebnis {
    let runningResult: KampfErgebnis = new KampfErgebnis(0, 0, 0);
    let result: KampfErgebnis | undefined = undefined;

    while ((result = this.fightOneWeek(true, targetDate)) != null) {
      runningResult = KampfErgebnis.combine(runningResult, result, this);
    }

    return runningResult;
  }

  /** Bringt Spieler eine Woche näher an das angegebene Datum, aber nicht darüber hinaus. */
  public fightOneWeek(
    timeWarping: boolean = false,
    targetDate: Date = new Date()
  ): KampfErgebnis | undefined {
    if (!this.canFight(timeWarping, targetDate)) {
      return undefined;
    }

    let ergebnis: KampfErgebnis = new KampfErgebnis(0, 0, 0);

    if (!this.isAfterFight()) {
      // Kampf für Rückblick speichern
      this.statistik.push(Statistik.erstelleAusSpieler(this));

      if (!this.krankheitsmodus) {
        ergebnis = Kampf.ergebnisAnwenden(this);
      }
    }

    if (this.canFight(true, targetDate)) {
      const loot: ItemReferenz[] = this.advanceWeek();
      ItemListeHelper.addLootToInventory(
        loot.map((i) => i.getPublishedItem()),
        ergebnis.loot,
        this
      );
    }

    return ergebnis;
  }

  private advanceWeek(): ItemReferenz[] {
    const loot: ItemReferenz[] = [];

    if (!this.krankheitsmodus) {
      ItemListeHelper.addLootToInventory(
        this.chestTracking.openAll(this),
        loot,
        this
      );
      ItemListeHelper.addLootToInventory(
        loot.map((i) => i.getPublishedItem()),
        this.inventar,
        this
      );
      this.chestTracking.advanceAll();

      this.activityTracking = [];
      for (let i = 0; i < 7; i++) {
        this.activityTracking[i] = new Tracking();
      }
    }

    const week = new Date(this.aktiveWoche);
    week.setDate(week.getDate() + 7);
    this.aktiveWoche = TypeHelper.getDateForWeek(week);

    for (let i = 0; i < 7; i++) {
      this.activityTracking[i].date = TypeHelper.getDateForWeek(
        this.aktiveWoche,
        i
      );
    }

    return loot;
  }

  public canFight(
    timeWarping: boolean = false,
    targetDate: Date = new Date()
  ): boolean {
    const today = new Date(targetDate);

    // Kein TimeWarp und es ist Sonntag
    if (!timeWarping && today.getDay() == 0) {
      // Wir tun so, als wäre schon Montag, damit wir in die nächste Woche kommen können
      today.setDate(today.getDate() + 1);
    }

    const weekNow = TypeHelper.getDateForWeek(today);
    const activeWeek = TypeHelper.getDateForWeek(this.aktiveWoche);

    return weekNow > activeWeek;
  }

  public isAfterFight(): boolean {
    return (
      this.statistik.slice(-1)[0]?.woche.getTime() == this.aktiveWoche.getTime()
    );
  }

  public loadFromSave(
    saveName: string,
    silent: boolean = false
  ): KampfErgebnis | Error {
    const saveString: string | null = localStorage.getItem(saveName);

    if (saveString == null) {
      return new Error(`Spielstand ${saveName} existiert nicht!`);
    }

    return this.import(saveString, silent);
  }

  private writeToSave(saveName: string, savegame: string): void {
    localStorage.setItem(saveName, savegame);
  }

  public savePlayer(asMainCharacter: boolean = false): void {
    const savegame: string = this.export();

    this.writeToSave(Spieler.SAVEGAME_PREFIX + this.name, savegame);

    if (asMainCharacter) {
      this.writeToSave(Spieler.MainCharacterSaveName, savegame);
    }
  }

  public import(
    saveString: string,
    silent: boolean = false
  ): KampfErgebnis | Error {
    try {
      const saveData: Optional<NonMethods<Spieler>> = JSON.parse(saveString);

      Object.assign(this, Spieler.cleanDefinition(saveData));
      this.reinstanciateComplexMembers();

      return this.timeWarpToDate(new Date());
    } catch (err: unknown) {
      if (!silent) console.error(err);
      if (err instanceof Error) {
        return err;
      }
      return new Error('Unbekannten Fehler erhalten!');
    }
  }

  public export(): string {
    return JSON.stringify(this);
  }

  public getExpRequiredForLevelUp(): number {
    return Spieler.getExpRequiredForLevelUp(this.level);
  }

  public getTotalMultiplicativeBonus(): number {
    return this.ausruestung
      .map((ref) => ref.createItem())
      .reduce(
        (soFar, item) => soFar * item.getMultiplicativeEquipmentBonus(),
        1
      );
  }

  public getTotalAdditiveBonus(): number {
    return this.ausruestung
      .map((ref) => ref.createItem())
      .reduce((soFar, item) => soFar + item.getAdditiveEquipmentBonus(), 0);
  }

  /** Berechnet {@link level} und {@link erfahrungUebrig} neu. */
  public updateLevel(): number {
    let nextLevelAt = Spieler.getExpRequiredForLevelUp(this.level);

    while (this.erfahrungUebrig >= nextLevelAt) {
      this.erfahrungUebrig -= nextLevelAt;
      this.level++;
      nextLevelAt = Spieler.getExpRequiredForLevelUp(this.level);
    }

    return this.level;
  }

  public getLevelBonus(): number {
    return 0.05 * this.level + 0.95;
  }

  public getNoEquipFightingPoints(): number {
    return this.getLevelBonus() * this.getTotalTrackingPoints();
  }

  public getFightingPoints(): number {
    return (
      (this.getNoEquipFightingPoints() + this.getTotalAdditiveBonus()) *
      this.getTotalMultiplicativeBonus()
    );
  }

  public getTotalTrackingPoints(): number {
    return this.activityTracking.reduce(
      (soFar, val) => soFar + val.getTotalTrackingPoints(),
      0
    );
  }

  public getAllTimeOpenedChests(): number {
    return this.statistik.reduce<number>((sum: number, stats: Statistik) => {
      return (
        sum +
        stats.truhen.reduce<number>(
          (truheSum: number, week: (boolean | undefined)[]) => {
            return truheSum + (week.every((val) => val === true) ? 1 : 0);
          },
          0
        )
      );
    }, 0);
  }

  public getAllTimeKilledEnemies(): number {
    return this.statistik.reduce<number>((sum: number, stats: Statistik) => {
      const snapshot: Spieler = stats.erstelleSpielerSnapshot();
      return (
        sum +
        Math.floor(
          GegnerListe.getByPlayer(snapshot).getKillCount(
            snapshot.getFightingPoints()
          )
        )
      );
    }, 0);
  }

  public hasItem(id: string): boolean {
    return ItemListeHelper.hasItem(id, this.inventar);
  }

  public getItemsById(id: string): ItemReferenz[] {
    return ItemListeHelper.getItemsById(id, this.inventar);
  }

  public addLootToInventory(items: Item[]): void {
    ItemListeHelper.addLootToInventory(items, this.inventar, this);
  }

  public addItemToInventory(addition: Item): void {
    ItemListeHelper.addItemToInventory(
      addition,
      this.inventar,
      this.getNoEquipFightingPoints()
    );
  }

  /** Verändert keine Referenzen, sondern sortiert nur! */
  public resortInventory(): void {
    ItemListeHelper.resortInventory(
      this.inventar,
      this.getNoEquipFightingPoints()
    );
  }

  /** Entfernt ein Item vom Typ {@link target}. Nimmt immer das letzte verfügbare Item. */
  public removeOneOfType(target: ItemId): boolean {
    return ItemListeHelper.removeOneOfType(target, this.inventar);
  }

  /** Entfernt ein Item aus GENAU dem {@link target}. Sollte nur mit dem letzten Item dieser Art im Inventar aufgerufen werden oder Unstackables. */
  public removeOneFrom(target?: ItemReferenz): boolean {
    return ItemListeHelper.removeOneFrom(target, this.inventar);
  }

  public equipItem(equipRef?: ItemReferenz): boolean {
    return ItemListeHelper.equipItem(
      equipRef,
      this.ausruestung,
      this.inventar,
      this.getNoEquipFightingPoints()
    );
  }

  public unequipItem(equipRef?: ItemReferenz): boolean {
    return ItemListeHelper.unequipItem(
      equipRef,
      this.ausruestung,
      this.inventar,
      this.getNoEquipFightingPoints()
    );
  }
}
