import {
  GegnerGruppe,
  GegnerTyp,
  GegnerElement,
  GegnerId,
} from 'src/app/services/gegner/gegner-enums';
import { BiomListe } from 'src/app/data/biom-liste';
import type { Item } from 'src/app/services/items/item';
import type { NonMethods, Optional } from 'src/app/services/type-helper';

export class Gegner {
  public typ: GegnerTyp = GegnerTyp.Reptil;
  public gruppe: GegnerGruppe = GegnerGruppe.Tier;
  public elemente: GegnerElement[] = [];

  public kartenNummer: number = 1;
  public feldNummer: number = 1;
  public level: number = 1;

  public id: GegnerId = GegnerId.Kein;
  public icon: string = '';
  public image: string = '';
  public name: string = '';
  public beschreibung: string = '';

  public biom: string = '';

  public loot: ((parent: Gegner) => Item[]) | undefined;

  public constructor(
    ...definitions: (Optional<NonMethods<Gegner>> | undefined)[]
  ) {
    let definition: Optional<NonMethods<Gegner>> = Object.assign(
      {},
      ...definitions
    );

    definition = Gegner.checkIfValidOrThrow(definition);

    Object.assign(this, definition);
  }

  public static checkIfValidOrThrow(
    gegner?: Optional<NonMethods<Gegner>>
  ): Optional<NonMethods<Gegner>> {
    const ret = Gegner.checkIfValid(gegner);

    if (ret instanceof Error) {
      throw ret;
    }

    return ret;
  }

  public static checkIfValid(
    gegner?: Optional<NonMethods<Gegner>>
  ): Optional<NonMethods<Gegner>> | Error {
    if (gegner == null) gegner = {};

    if (gegner.kartenNummer == null) gegner.kartenNummer = 0;

    if (gegner.feldNummer == null) gegner.feldNummer = 0;

    if (gegner.level == null) gegner.level = 0;

    if (gegner.id == null || gegner.id.length <= 0) {
      return new Error('Gegner müssen eine ID haben!');
    }

    if (gegner.typ == null) {
      return new Error('Gegner müssen einen Typ haben!');
    }

    if (gegner.gruppe == null) {
      return new Error('Gegner müssen eine Gruppe haben!');
    }

    if (gegner.loot == null) {
      return new Error('Gegner müssen Loot haben!');
    }

    if (gegner.biom == null || gegner.biom.length <= 0) {
      return new Error('Gegner müssen ein Biom haben!');
    }

    if (!BiomListe.checkForId(gegner.biom)) {
      return new Error('Gegner-Biom muss existieren!');
    }

    if (gegner.elemente == null || gegner.elemente.length <= 0) {
      return new Error('Gegner müssen mindestens ein Element haben!');
    }

    if (gegner.icon == null || gegner.icon.length <= 0) {
      return new Error('Gegner müssen ein Icon haben!');
    }

    if (gegner.image == null || gegner.image.length <= 0) {
      return new Error('Gegner müssen ein Bild haben!');
    }

    if (gegner.name == null || gegner.name.length <= 0) {
      return new Error('Gegner müssen einen Namen haben!');
    }

    if (!Number.isInteger(gegner.kartenNummer) || gegner.kartenNummer < 1) {
      return new Error('Karten-Nummer muss Ganzzahl größer gleich 1 sein!');
    }

    if (!Number.isInteger(gegner.feldNummer) || gegner.feldNummer < 1) {
      return new Error('Karten-Nummer muss Ganzzahl größer gleich 1 sein!');
    }

    if (!Number.isInteger(gegner.level) || gegner.level < 1) {
      return new Error('Karten-Nummer muss Ganzzahl größer gleich 1 sein!');
    }

    return gegner;
  }

  public getTotalExp(fightPoints: number): number {
    return fightPoints * (this.level + 9);
  }

  public getFightPointLevelScaling(factor: number): number {
    return factor * (3 * this.level ** 2 + this.level + 21);
  }

  public getBaseExp(): number {
    return this.getTotalExp(55 / 7);
  }

  public getKillCount(fightPoints: number): number {
    return this.getTotalExp(fightPoints) / this.getFightPointLevelScaling(2.8);
  }

  public getFightPointsNeeded(): number {
    return 1 / this.getKillCount(1);
  }

  public generateLoot(): Item[] {
    return this.loot?.(this) ?? [];
  }
}
