import { BehaviorSubject } from 'rxjs';
import { ItemListe } from 'src/app/data/item-liste';
import { GegnerId } from 'src/app/services/gegner/gegner-enums';
import { Item } from 'src/app/services/items/item';
import {
  AusruestungTyp,
  ItemId,
  ItemSeltenheit,
} from 'src/app/services/items/item-enums';
import type { NonMethods, Optional } from 'src/app/services/type-helper';

export class ItemReferenz {
  public id: ItemId = ItemId.Kein;

  /** Sollte Gewöhnlich sein, wenn nicht gesetzt */
  public seltenheit?: ItemSeltenheit = undefined;
  /** Sollte 1 sein, wenn nicht gesetzt */
  public anzahl?: number = undefined;
  /** Sollte 1 sein, wenn nicht gesetzt */
  public level?: number = undefined;
  /** Sollte 0 sein, wenn nicht gesetzt */
  public sterne?: number = undefined;
  /** Sollte 0 sein, wenn nicht gesetzt */
  public upgradeLevel?: number = undefined;
  /**
   * Sollte 0 sein, wenn nicht gesetzt.
   * Wird nur bei Pets ausgewertet.
   */
  public additiveBonus?: number = undefined;
  /** Sollte GegnerId.Kein = '' sein, wenn nicht gesetzt */
  public gegnerRef?: GegnerId = undefined;

  private subject: BehaviorSubject<Item> = new BehaviorSubject<Item>(
    this.createItem(true)
  );

  public constructor(...definitions: Optional<NonMethods<ItemReferenz>>[]) {
    const item: Optional<NonMethods<ItemReferenz>> = Object.assign(
      {},
      ...definitions
    );

    if (definitions.length <= 0) {
      // leerer Konstruktor
      item.id = ItemId.EmptySlot;
    }

    const ret = ItemReferenz.checkIfValidOrThrow(item);

    Object.assign(this, ret);
    this.reinstanciateComplexMembers();
  }

  /**
   * Erstellt alle Subtypen neu, damit eine Kopie des Items unabhängige Eigenschaften von einer anderen Kopie dieses Items hat.
   * Macht properties die nicht im JSON auftauchen sollen außerdem not enumerable.
   */
  private reinstanciateComplexMembers(): void {
    this.subject = new BehaviorSubject<Item>(this.createItem(true));
    Object.defineProperty(this, 'subject', { enumerable: false });
  }

  public static checkIfValidOrThrow(
    item?: Optional<NonMethods<ItemReferenz>>
  ): Optional<NonMethods<ItemReferenz>> {
    const ret = ItemReferenz.checkIfValid(item);

    if (ret instanceof Error) {
      throw ret;
    }

    return ret;
  }

  public static checkIfValid(
    item?: Optional<NonMethods<ItemReferenz>> & Optional<NonMethods<Item>>
  ): Optional<NonMethods<ItemReferenz>> | Error {
    if (item == null) item = {};

    if (item.id == null) item.id = ItemId.Kein;

    if (item.upgradeLevel == null)
      item.upgradeLevel = item.equipmentStats?.upgradeLevel ?? undefined;

    if (item.additiveBonus == null)
      item.additiveBonus = item.equipmentStats?.additiveBonus ?? undefined;

    if (item.id.length <= 0) {
      return new Error('Item-Referenzen brauchen eine ID!');
    }

    if (!ItemListe.checkForId(item.id)) {
      return new Error('Item-Referenz hat ungültige ID: ' + item.id);
    }

    if (
      item.anzahl != null &&
      (!Number.isInteger(item.anzahl) || item.anzahl < 1)
    ) {
      return new Error('Anzahl muss Ganzzahl größer gleich 1 sein!');
    }

    if (
      item.level != null &&
      (!Number.isInteger(item.level) || item.level < 1)
    ) {
      return new Error('Level muss Ganzzahl größer gleich 1 sein!');
    }

    if (
      item.sterne != null &&
      (!Number.isInteger(item.sterne) || item.sterne < 0)
    ) {
      return new Error('Sterne muss Ganzzahl größer gleich 0 sein!');
    }

    if (
      item.upgradeLevel != null &&
      (!Number.isInteger(item.upgradeLevel) ||
        item.upgradeLevel < 0 ||
        item.upgradeLevel > 9)
    ) {
      return new Error(
        'Upgrade-Level muss Ganzzahl zwischen 0 und 9 (inklusive) sein!'
      );
    }

    if (item.additiveBonus != null && item.id != ItemId.Pet) {
      return new Error('Nur Pets dürfen explizit definierte Kampfboni haben!');
    }

    if (
      item.additiveBonus != null &&
      (!Number.isInteger(item.additiveBonus) || item.additiveBonus < 0)
    ) {
      return new Error('Kampfbonus muss Ganzzahl größer gleich 0 sein!');
    }

    if (item.anzahl == 1) item.anzahl = undefined;

    if (item.level == 1) item.level = undefined;

    if (item.seltenheit == ItemSeltenheit.Gewoehnlich)
      item.seltenheit = undefined;

    if (item.upgradeLevel == 0) item.upgradeLevel = undefined;

    if (item.additiveBonus == 0) item.additiveBonus = undefined;

    if (item.sterne == 0) item.sterne = undefined;

    if (item.gegnerRef == GegnerId.Kein) item.gegnerRef = undefined;

    // entfernt alle nicht definierten Eigenschaften
    return {
      id: item.id,
      anzahl: item.anzahl,
      level: item.level,
      sterne: item.sterne,
      seltenheit: item.seltenheit,
      upgradeLevel: item.upgradeLevel,
      additiveBonus: item.additiveBonus,
      gegnerRef: item.gegnerRef,
    } as NonMethods<ItemReferenz>;
  }

  public static kannStacken(ziel: ItemReferenz, quelle: ItemReferenz): boolean {
    return (
      ziel.isCompatibleWith(quelle) &&
      Item.kannStacken(ziel.getPublishedItem(), quelle.getPublishedItem())
    );
  }

  /**
   * Stackt Quelle auf Ziel (falls möglich), schreibt in den Werten beider Items.
   *
   * Quelle kann danach das leere Item sein.
   *
   * Gibt true zurück, wenn etwas geändert wurde.
   */
  public static stack(zielRef: ItemReferenz, quelleRef: ItemReferenz): boolean {
    if (!zielRef.isCompatibleWith(quelleRef)) return false;

    const zielItem: Item = zielRef.getPublishedItem();
    const quelleItem: Item = quelleRef.getPublishedItem();

    if (!Item.stack(zielItem, quelleItem)) return false;

    zielRef.anzahl = zielItem.anzahl;
    zielRef.publishItem();
    if (quelleItem.id == ItemId.Kein) {
      quelleRef.destroy();
    } else {
      quelleRef.anzahl = quelleItem.anzahl;
      quelleRef.publishItem();
    }

    return true;
  }

  public static compare(
    baseDmg: number,
    ref1: ItemReferenz,
    ref2: ItemReferenz
  ): number {
    return Item.compare(
      baseDmg,
      ref1.getPublishedItem(),
      ref2.getPublishedItem()
    );
  }

  public isCompatibleWith(item: Item | ItemReferenz): boolean {
    if (item instanceof Item) {
      return (
        this.id == item.id &&
        (this.level ?? 1) == item.level &&
        (this.sterne ?? 0) == item.sterne &&
        (this.seltenheit ?? ItemSeltenheit.Gewoehnlich) == item.seltenheit &&
        (this.upgradeLevel ?? 0) == (item.equipmentStats?.upgradeLevel ?? 0) &&
        (item.ausruestungTyp != AusruestungTyp.Tier ||
          (this.additiveBonus ?? 0) ==
            (item.equipmentStats?.additiveBonus ?? 0)) &&
        (this.gegnerRef ?? GegnerId.Kein) == item.gegnerRef
      );
    }

    return (
      this !== item &&
      this.id == item.id &&
      (this.level ?? 1) == (item.level ?? 1) &&
      (this.sterne ?? 0) == (item.sterne ?? 0) &&
      (this.seltenheit ?? ItemSeltenheit.Gewoehnlich) ==
        (item.seltenheit ?? ItemSeltenheit.Gewoehnlich) &&
      (this.upgradeLevel ?? 0) == (item.upgradeLevel ?? 0) &&
      (this.additiveBonus ?? 0) == (item.additiveBonus ?? 0) &&
      (this.gegnerRef ?? GegnerId.Kein) == (item.gegnerRef ?? GegnerId.Kein)
    );
  }

  public createItem(forceCreation: boolean = false): Item {
    const item = ItemListe.getByIdWithChanges(this.id, {
      anzahl: forceCreation ? 1 : this.anzahl,
      level: this.level,
      sterne: this.sterne,
      seltenheit: this.seltenheit,
      gegnerRef: this.gegnerRef,
    });

    if (forceCreation) {
      // umgeht Checks im Item Constructor (für Display Items)
      item.anzahl = this.anzahl ?? 1;
    }

    if (item.equipmentStats != null) {
      item.equipmentStats.upgradeLevel = this.upgradeLevel ?? 0;

      if (item.id == ItemId.Pet) {
        item.equipmentStats.additiveBonus = this.additiveBonus ?? 0;
      }
    }
    return item;
  }

  public getItemSubject(): BehaviorSubject<Item> {
    return this.subject;
  }

  public publishItem(): void {
    this.subject.next(this.createItem(true));
  }

  public getPublishedItem(): Item {
    return this.subject.value;
  }

  public destroy(): void {
    this.id = ItemId.Kein;
    this.anzahl = undefined;
    this.level = undefined;
    this.sterne = undefined;
    this.upgradeLevel = undefined;
    this.additiveBonus = undefined;
    this.seltenheit = undefined;
    this.gegnerRef = undefined;

    this.publishItem();
    this.subject.complete();
  }

  public getItemText(): string {
    return `${this.id} 
      (${this.anzahl ?? 1},
      Lvl${this.level ?? 1},
      +${this.upgradeLevel ?? 0},
      ${this.sterne ?? 0}⭐,
      ${this.additiveBonus ?? 0}🗡,
      🚩${this.seltenheit ?? ItemSeltenheit.Gewoehnlich},
      Gegner:${this.gegnerRef ?? GegnerId.Kein})`;
  }
}
