import type { NonMethods, Optional } from 'src/app/services/type-helper';

export class Biom {
  public id: string = '';
  public name: string = '';
  public hintergrund: string = '';

  public constructor(
    ...definitions: (Optional<NonMethods<Biom>> | undefined)[]
  ) {
    let definition: Optional<Biom> = Object.assign({}, ...definitions);

    definition = Biom.checkIfValidOrThrow(definition);

    Object.assign(this, definition);
  }

  public static checkIfValidOrThrow(
    biom?: Optional<NonMethods<Biom>>
  ): Optional<NonMethods<Biom>> {
    const ret = Biom.checkIfValid(biom);

    if (ret instanceof Error) {
      throw ret;
    }

    return ret;
  }

  public static checkIfValid(
    biom?: Optional<NonMethods<Biom>>
  ): Optional<NonMethods<Biom>> | Error {
    if (biom == null) biom = {};

    if (biom.id == null || biom.id.length <= 0) {
      return new Error('Biom muss eine ID haben!');
    }

    if (biom.name == null || biom.name.length <= 0) {
      return new Error('Biom muss einen Namen haben!');
    }

    if (biom.hintergrund == null || biom.hintergrund.length <= 0) {
      return new Error('Biom muss einen Hintergrund haben!');
    }

    return biom;
  }
}
