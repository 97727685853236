import {
  ItemSeltenheit,
  ItemTyp,
  AusruestungTyp,
  EquipmentStats,
  ItemId,
} from './item-enums';
import { NonMethods, Optional } from 'src/app/services/type-helper';
import { ItemListe } from 'src/app/data/item-liste';
import { ItemReferenz } from 'src/app/services/items/item-referenz';
import { GegnerListe } from 'src/app/data/gegner-liste';
import { GegnerId } from 'src/app/services/gegner/gegner-enums';
import type { Spieler } from 'src/app/services/spieler/spieler';

export class Item {
  public typ: ItemTyp = ItemTyp.Kein;
  public seltenheit: ItemSeltenheit = ItemSeltenheit.Gewoehnlich;
  public ausruestungTyp: AusruestungTyp = AusruestungTyp.Kein;
  public lootTyp: ItemTyp = ItemTyp.Kein;

  public id: ItemId = ItemId.Kein;
  public icon: string = '';
  public name: string = '';
  public beschreibung: string = '';

  public anzahl: number = 1;
  public stackSize: number = 1;
  public level: number = 1;
  public sterne: number = 0;

  public kartenNummer: number = 1;

  public loot: ((parent: Item, nutzer: Spieler) => Item[]) | undefined;
  public equipmentStats: EquipmentStats | undefined;

  public gegnerRef: GegnerId = GegnerId.Kein;

  public constructor(
    ...definitions: (Optional<NonMethods<Item>> | undefined)[]
  ) {
    const item: Optional<NonMethods<Item>> = Object.assign({}, ...definitions);

    const ret = Item.checkIfValidOrThrow(item);

    Object.assign(this, ret);
    this.reinstanciateComplexMembers();
  }

  public static checkIfValidOrThrow(
    item?: Optional<NonMethods<Item>>
  ): Optional<NonMethods<Item>> {
    const ret = Item.checkIfValid(item);

    if (ret instanceof Error) {
      throw ret;
    }

    return ret;
  }

  public static checkIfValid(
    item?: Optional<NonMethods<Item>>
  ): Optional<NonMethods<Item>> | Error {
    if (item == null) item = {};

    if (item.typ == null) item.typ = ItemTyp.Kein;

    if (item.seltenheit == null) item.seltenheit = ItemSeltenheit.Gewoehnlich;

    if (item.ausruestungTyp == null) item.ausruestungTyp = AusruestungTyp.Kein;

    if (item.lootTyp == null) item.lootTyp = ItemTyp.Kein;

    if (item.id == null) item.id = ItemId.Kein;

    if (item.gegnerRef == null) item.gegnerRef = GegnerId.Kein;

    if (
      item.gegnerRef != GegnerId.Kein &&
      GegnerListe.checkForId(item.gegnerRef)
    ) {
      const gegner = GegnerListe.getById(item.gegnerRef);

      item.name = gegner.name;
      item.beschreibung = gegner.beschreibung;
      item.icon = gegner.icon;
      item.kartenNummer = gegner.kartenNummer;
    }

    if (item.name == null) item.name = '';

    if (item.icon == null) item.icon = '';

    if (item.beschreibung == null) item.beschreibung = '';

    if (item.kartenNummer == null) item.kartenNummer = 1;

    if (item.anzahl == null) item.anzahl = 1;

    if (item.stackSize == null) item.stackSize = 1;

    if (item.level == null) item.level = 1;

    if (item.sterne == null) item.sterne = 0;

    if (
      item.typ == ItemTyp.Equipment &&
      (item.ausruestungTyp == AusruestungTyp.Kein ||
        item.equipmentStats == null)
    ) {
      return new Error('Ausrüstung muss einen Typ und Stats haben!');
    }

    if (
      item.typ != ItemTyp.Equipment &&
      (item.ausruestungTyp != AusruestungTyp.Kein ||
        item.equipmentStats != null)
    ) {
      return new Error('Nur Ausrüstung darf Ausrüstungs-Typ oder Stats haben!');
    }

    if (item.typ == ItemTyp.Truhe && item.loot == null) {
      item.loot = ItemListe.generateChestLoot;
    }

    if (item.typ == ItemTyp.KampfItem && item.loot == null) {
      item.loot = ItemListe.generateKampfItemLoot;
    }

    if ([ItemTyp.Truhe, ItemTyp.KampfItem].includes(item.typ)) {
      if (item.lootTyp == ItemTyp.Kein || item.loot == null) {
        return new Error(
          'Truhen und Kampf-Items müssen einen Loot-Typ und Loot haben!'
        );
      }
    } else {
      if (item.lootTyp != ItemTyp.Kein || item.loot != null) {
        return new Error(
          'Nur Truhen oder Kampf-Items dürfen Loot-Typ oder Loot haben!'
        );
      }
    }

    if (item.typ == ItemTyp.Kein) {
      if (item.seltenheit != ItemSeltenheit.Gewoehnlich) {
        return new Error('Leere Slots dürfen nur gewöhnlich sein!');
      }

      if (item.id != ItemId.Kein) {
        return new Error('Leere Slots dürfen keine ID haben!');
      }

      if (item.name != '') {
        return new Error('Leere Slots dürfen keinen Namen haben!');
      }

      if (item.icon != '') {
        return new Error('Leere Slots dürfen kein Icon haben!');
      }
    } else {
      if (item.id == ItemId.Kein) {
        return new Error('Items müssen eine ID haben!');
      }

      if (item.name == '') {
        return new Error('Items müssen einen Namen haben!');
      }

      if (item.icon == '') {
        return new Error('Items müssen ein Icon haben!');
      }
    }

    if (!Number.isInteger(item.kartenNummer) || item.kartenNummer < 1) {
      return new Error('Kartennummer muss Ganzzahl größer gleich 1 sein!');
    }

    if (!Number.isInteger(item.level) || item.level < 1) {
      return new Error('Level muss Ganzzahl größer gleich 1 sein!');
    }

    if (!Number.isInteger(item.sterne) || item.sterne < 0) {
      return new Error('Sterne muss Ganzzahl größer gleich 0 sein!');
    }

    if (!Number.isInteger(item.anzahl) || item.anzahl < 1) {
      return new Error('Anzahl muss Ganzzahl größer gleich 1 sein!');
    }

    if (!Number.isInteger(item.stackSize) || item.stackSize < 1) {
      return new Error('StackSize muss Ganzzahl größer gleich 1 sein!');
    }

    if (item.anzahl > item.stackSize) {
      return new Error('Anzahl muss kleiner gleich StackSize sein!');
    }

    if (item.typ == ItemTyp.Kein && (item.anzahl > 1 || item.stackSize > 1)) {
      return new Error(
        'Leere Slots dürfen nicht stacken und müssen Menge 1 haben.'
      );
    }

    if (
      [ItemTyp.Truhe, ItemTyp.Equipment].includes(item.typ) &&
      item.stackSize > 1
    ) {
      return new Error('Truhen und Equipment dürfen nicht stacken!');
    }

    if (
      item.ausruestungTyp != AusruestungTyp.Tier &&
      item.gegnerRef != GegnerId.Kein
    ) {
      return new Error('Nur Tiere dürfen eine Gegner-Referenz haben!');
    }

    return item;
  }

  public static kannStacken(ziel: Item, quelle: Item): boolean {
    return (
      ziel !== quelle &&
      ziel.stackSize > 1 &&
      ziel.stackSize > ziel.anzahl &&
      ziel.stackSize == quelle.stackSize &&
      ziel.id == quelle.id &&
      ziel.typ == quelle.typ &&
      ziel.lootTyp == quelle.lootTyp &&
      ziel.ausruestungTyp == quelle.ausruestungTyp &&
      ziel.name == quelle.name &&
      ziel.icon == quelle.icon &&
      ziel.beschreibung == quelle.beschreibung &&
      ziel.seltenheit == quelle.seltenheit &&
      ziel.kartenNummer == quelle.kartenNummer &&
      ziel.level == quelle.level &&
      ziel.sterne == quelle.sterne
    );
  }

  /**
   * Stackt Quelle auf Ziel (falls möglich), schreibt in den Werten beider Items.
   *
   * Quelle kann danach das leere Item sein.
   *
   * Gibt true zurück, wenn etwas geändert wurde.
   */
  public static stack(ziel: Item, quelle: Item): boolean {
    if (!Item.kannStacken(ziel, quelle)) return false;

    const platzImZiel: number = ziel.stackSize - ziel.anzahl;
    if (platzImZiel <= 0) return false;

    const transfer: number = Math.min(platzImZiel, quelle.anzahl);

    quelle.anzahl -= transfer;
    ziel.anzahl += transfer;

    if (quelle.anzahl <= 0) {
      quelle.destroy();
    }
    return true;
  }

  public static compare(baseDmg: number, itemA: Item, itemB: Item): number {
    const typOrder: ItemTyp[] = [
      ItemTyp.Equipment,
      ItemTyp.Schmiedebuff,
      ItemTyp.KampfItem,
      ItemTyp.Sonstiges,
      ItemTyp.UpgradeItem,
      ItemTyp.CraftingItem,
      ItemTyp.Truhe,
      ItemTyp.Kein,
    ];

    if (itemA.typ != itemB.typ) {
      return typOrder.indexOf(itemA.typ) - typOrder.indexOf(itemB.typ);
    }

    const ausruestungOrder: AusruestungTyp[] = [
      AusruestungTyp.Helm,
      AusruestungTyp.Ruestung,
      AusruestungTyp.Handschuhe,
      AusruestungTyp.Schuhe,
      AusruestungTyp.Halskette,
      AusruestungTyp.Guertel,
      AusruestungTyp.Ring,
      AusruestungTyp.Umhang,
      AusruestungTyp.HandRechts,
      AusruestungTyp.HandLinks,
      AusruestungTyp.Zusatz,
      AusruestungTyp.Tier,
      AusruestungTyp.Kein,
    ];

    if (itemA.ausruestungTyp != itemB.ausruestungTyp) {
      return (
        ausruestungOrder.indexOf(itemA.ausruestungTyp) -
        ausruestungOrder.indexOf(itemB.ausruestungTyp)
      );
    }

    if (itemA.kartenNummer != itemB.kartenNummer) {
      return itemB.kartenNummer - itemA.kartenNummer;
    }

    const bonusA: number = itemA.getSingleItemBonus(baseDmg);
    const bonusB: number = itemB.getSingleItemBonus(baseDmg);
    if (bonusA != bonusB) {
      return bonusB - bonusA;
    }

    if (itemA.seltenheit != itemB.seltenheit) {
      return itemB.seltenheit - itemA.seltenheit;
    }

    if (itemA.sterne != itemB.sterne) {
      return itemB.sterne - itemA.sterne;
    }

    if (itemA.level != itemB.level) {
      return itemB.level - itemA.level;
    }

    if (itemA.name != itemB.name) {
      return itemA.name.localeCompare(itemB.name);
    }

    if (itemA.anzahl != itemB.anzahl) {
      return itemB.anzahl - itemA.anzahl;
    }

    return 0;
  }

  /**
   * Erstellt alle Subtypen neu, damit eine Kopie dieses Items unabhängige Eigenschaften von einer anderen Kopie dieses Items hat.
   */
  private reinstanciateComplexMembers(): void {
    if (this.equipmentStats != null)
      this.equipmentStats = new EquipmentStats(this.equipmentStats);
  }

  public checkSelf(): void {
    Item.checkIfValidOrThrow(this);
  }

  public withChanges(...changes: Optional<NonMethods<Item>>[]): Item {
    // Kopie erstellen mit Änderungen
    return new Item(this, ...changes);
  }

  public withStatChanges(changes: Optional<NonMethods<EquipmentStats>>): Item {
    const changedItem = new Item(this);

    if (changedItem.equipmentStats == null) {
      throw new Error('Kann nur existierendes Equipment verändern!');
    }

    const changedStats = Object.assign(changedItem.equipmentStats, changes);
    changedItem.equipmentStats = new EquipmentStats(changedStats);
    changedItem.checkSelf();

    return changedItem;
  }

  public static getRarityBonus(seltenheit: ItemSeltenheit): number {
    switch (seltenheit) {
      case ItemSeltenheit.Gewoehnlich:
        return 1;
      case ItemSeltenheit.Ungewoehnlich:
        return 1.5;
      case ItemSeltenheit.Selten:
        return 2;
      case ItemSeltenheit.Episch:
        return 2.5;
      case ItemSeltenheit.Legendaer:
      case ItemSeltenheit.Mythisch:
        return 4;
      default:
        return 1;
    }
  }

  public static getSeltenheitCssClass(seltenheit?: ItemSeltenheit): string {
    switch (seltenheit) {
      case ItemSeltenheit.Gewoehnlich:
        return 'seltenheit-gewoehnlich';
      case ItemSeltenheit.Ungewoehnlich:
        return 'seltenheit-ungewoehnlich';
      case ItemSeltenheit.Selten:
        return 'seltenheit-selten';
      case ItemSeltenheit.Episch:
        return 'seltenheit-episch';
      case ItemSeltenheit.Legendaer:
        return 'seltenheit-legendaer';
      case ItemSeltenheit.Mythisch:
        return 'seltenheit-mythisch';
    }

    return 'seltenheit-gewoehnlich';
  }

  public getAdditiveEquipmentBonus(): number {
    if (this.typ != ItemTyp.Equipment || this.equipmentStats == null) return 0;

    let bonus = this.equipmentStats.additiveBonus;

    // Upgrade Level nur anwenden, wenn der Gegenstand hier schon Werte hat
    if (bonus != 0) {
      bonus += this.equipmentStats.upgradeLevel + this.sterne;
    }

    return bonus * Item.getRarityBonus(this.seltenheit);
  }

  public getMultiplicativeEquipmentBonus(): number {
    if (this.typ != ItemTyp.Equipment || this.equipmentStats == null) return 1;

    // nur den Bonus multiplizieren, nicht die ursprünglichen 100%
    let bonus = this.equipmentStats.multiplicativeBonus - 1;

    // Upgrade Level nur anwenden, wenn der Gegenstand hier schon Werte hat
    if (bonus != 0) {
      bonus += (this.equipmentStats.upgradeLevel + this.sterne) / 100;
    }

    bonus *= Item.getRarityBonus(this.seltenheit);

    return bonus + 1;
  }

  /** Berechnet den Gesamtschaden für einen Basisschaden und nur dieses ausgerüstete Item. */
  public getSingleItemBonus(baseDmg: number): number {
    return (
      (baseDmg + this.getAdditiveEquipmentBonus()) *
      this.getMultiplicativeEquipmentBonus()
    );
  }

  /** Vernichtet alle Informationen die an diesem Item gespeichert sind um versehentlicher Weiterverwendung vorzubeugen. */
  public destroy(): void {
    this.typ = ItemTyp.Kein;
    this.anzahl = 1;
    this.stackSize = 1;
    this.level = 1;
    this.sterne = 0;
    this.kartenNummer = 1;
    this.id = ItemId.Kein;
    this.icon = '';
    this.beschreibung = '';
    this.ausruestungTyp = AusruestungTyp.Kein;
    this.lootTyp = ItemTyp.Kein;
    this.loot = undefined;
    this.equipmentStats = undefined;
    this.seltenheit = ItemSeltenheit.Gewoehnlich;
  }

  public getReferenz(): ItemReferenz {
    const referenzDefinition: Optional<NonMethods<ItemReferenz>> = {
      id: this.id,
    };

    if (this.seltenheit != ItemSeltenheit.Gewoehnlich) {
      referenzDefinition.seltenheit = this.seltenheit;
    }

    if (this.anzahl != 1) {
      referenzDefinition.anzahl = this.anzahl;
    }

    if (this.level != 1) {
      referenzDefinition.level = this.level;
    }

    if (this.sterne != 0) {
      referenzDefinition.sterne = this.sterne;
    }

    if (this.equipmentStats != null) {
      if (this.equipmentStats.upgradeLevel != 0) {
        referenzDefinition.upgradeLevel = this.equipmentStats.upgradeLevel;
      }

      if (this.equipmentStats.additiveBonus != 0 && this.id == ItemId.Pet) {
        referenzDefinition.additiveBonus = this.equipmentStats.additiveBonus;
      }
    }

    if (this.gegnerRef != GegnerId.Kein) {
      referenzDefinition.gegnerRef = this.gegnerRef;
    }

    return new ItemReferenz(referenzDefinition);
  }
}
