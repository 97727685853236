import { ItemTyp } from 'src/app/services/items/item-enums';
import { TrackedChest } from 'src/app/services/tracking/tracking-types';
import type { Item } from 'src/app/services/items/item';
import type { Spieler } from 'src/app/services/spieler/spieler';
import type { NonMethods, Optional } from 'src/app/services/type-helper';

export class ChestTracking {
  public static readonly truhenTemplate: TrackedChest[] = [
    new TrackedChest({
      id: 'chest1',
      name: 'NoDigital',
      editierbar: true,
    }),
    new TrackedChest({
      id: 'chest2',
      name: 'NoSweets',
      editierbar: true,
    }),
    new TrackedChest({
      id: 'chest3',
      name: 'NoSnooze',
      editierbar: true,
    }),
    new TrackedChest({
      id: 'chest4',
      name: 'NoDrugs',
      editierbar: true,
    }),
  ];

  public readonly truhen: TrackedChest[] = [];
  public combiChestComponents: string[] = [];
  public favoritId: string = '';

  constructor(definition?: Optional<NonMethods<ChestTracking>>) {
    // Standardwerte auswürfeln
    this.rerollCombiChest();

    // Werte laden
    Object.assign(this, definition);
    this.reinstanciateComplexMembers();

    if (definition == null) {
      // richtige Truhenverteilung sicherstellen
      this.advanceAll();
    }
  }

  /**
   * Erstellt alle Subtypen neu, damit eine Kopie des Trackings unabhängige Eigenschaften von einer anderen Kopie dieses Trackings hat.
   */
  private reinstanciateComplexMembers(): void {
    const inputTruhen: TrackedChest[] = [];

    for (let i = 0; i < this.truhen.length; i++) {
      inputTruhen.push(new TrackedChest(this.truhen[i]));
    }

    this.truhen.splice(0, this.truhen.length);

    for (let template of ChestTracking.truhenTemplate) {
      const input = inputTruhen.find((truhe) => truhe.id == template.id);
      if (input != null) {
        input.editierbar = template.editierbar;
        this.truhen.push(input);
      } else {
        this.truhen.push(new TrackedChest(template));
      }
    }
  }

  private rerollCombiChest(): void {
    const combiChest = this.truhen.find(
      (t) => t.id == TrackedChest.COMBI_CHEST_ID
    );
    if (combiChest == null) return;

    const possibleOrigins: string[] = ['chest1', 'chest2', 'chest3', 'chest4'];

    const id1: string = possibleOrigins.splice(
      Math.floor(Math.random() * possibleOrigins.length),
      1
    )[0];
    const id2: string = possibleOrigins.splice(
      Math.floor(Math.random() * possibleOrigins.length),
      1
    )[0];

    const source1 = this.truhen.find((t) => t.id == id1);
    const source2 = this.truhen.find((t) => t.id == id2);

    if (source1 == null || source2 == null) return;

    this.combiChestComponents = [id1, id2];

    combiChest.name = 'Wochentruhe: ' + source1.name + ' & ' + source2.name;
  }

  public updateCombiChest(): void {
    const combiChest = this.truhen.find(
      (t) => t.id == TrackedChest.COMBI_CHEST_ID
    );
    if (combiChest == null) return;

    const names = this.combiChestComponents
      .map((id) => this.truhen.find((chest) => chest.id == id)?.name ?? '')
      .filter((name) => name != null && name.length > 0);

    if (names.length <= 0) {
      combiChest.name = 'Wochentruhe (Kombi)';
    } else {
      combiChest.name = 'Wochentruhe: ' + names.join(' & ');
    }
  }

  public open(target: Spieler, truhe: TrackedChest): Item[] {
    return truhe.open(target, this.favoritId == truhe.id);
  }

  public openAll(target: Spieler): Item[] {
    return this.truhen.flatMap(this.open.bind(this, target));
  }

  public advanceAll(): void {
    const typenGesamt: ItemTyp[] = [
      ItemTyp.Equipment,
      ItemTyp.CraftingItem,
      ItemTyp.UpgradeItem,
      ItemTyp.Sonstiges,
    ];

    const typen: ItemTyp[] = [];

    for (let truhe of this.truhen) {
      if (typen.length <= 0) {
        typen.push(...typenGesamt);
      }
      let typ = ItemTyp.Kein;

      if (truhe.id != TrackedChest.DAILY_CHEST_ID) {
        typ = typen.splice(Math.floor(Math.random() * typen.length), 1)[0];
      }
      truhe.advanceWeek(typ);
    }

    this.rerollCombiChest();
  }

  public setFavorit(chest?: TrackedChest): void {
    if (chest?.id == TrackedChest.DAILY_CHEST_ID) {
      chest = undefined;
    }

    this.favoritId = chest?.id ?? '';
  }
}
