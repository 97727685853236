import { GegnerListe } from 'src/app/data/gegner-liste';
import { ItemId } from 'src/app/services/items/item-enums';
import type { Gegner } from 'src/app/services/gegner/gegner';
import type { Item } from 'src/app/services/items/item';
import type { ItemReferenz } from 'src/app/services/items/item-referenz';
import type { Spieler } from 'src/app/services/spieler/spieler';
import { ItemListeHelper } from 'src/app/services/items/item-liste.helper';
import { ItemListe } from 'src/app/data/item-liste';

export class Kampf {
  public static ergebnisVorhersagen(
    spieler: Spieler,
    gegner?: Gegner
  ): KampfErgebnis {
    const fightPoints: number = spieler.getFightingPoints();

    if (gegner == null) {
      gegner = GegnerListe.getByPlayer(spieler);
    }

    return new KampfErgebnis(
      gegner.getKillCount(fightPoints),
      gegner.getTotalExp(fightPoints),
      gegner.getFightPointsNeeded()
    );
  }

  public static ergebnisAnwenden(spieler: Spieler): KampfErgebnis {
    const gegner: Gegner = GegnerListe.getByPlayer(spieler);
    const petOrb: Item = ItemListe.getById(ItemId.PetOrb);

    const ergebnis = Kampf.ergebnisVorhersagen(spieler, gegner);
    const loot: Item[] = [];

    for (let i = 0; i < ergebnis.killCount; i++) {
      loot.push(...gegner.generateLoot());

      if (spieler.maxPetOrbs > i && spieler.removeOneOfType(ItemId.PetOrb)) {
        const pet: Item[] = petOrb.loot?.(petOrb, spieler) ?? [];
        loot.push(...pet);
      }
    }

    // Loot organisiert aufschreiben
    ItemListeHelper.addLootToInventory(loot, ergebnis.loot, spieler);

    // generierten Loot hinzufügen
    spieler.addLootToInventory(
      ergebnis.loot.map((val) => val.getPublishedItem())
    );
    spieler.erfahrungUebrig += ergebnis.expGained;
    spieler.updateLevel();

    return ergebnis;
  }
}

export class KampfErgebnis {
  public readonly killCount: number = 0;
  public readonly expGained: number = 0;
  public readonly fightPointsNeeded: number = 0;
  public readonly loot: ItemReferenz[] = [];

  public constructor(
    kills: number,
    xp: number,
    fpNeed: number,
    loot: ItemReferenz[] = []
  ) {
    this.killCount = Math.floor(kills);
    this.expGained = xp;
    this.fightPointsNeeded = fpNeed;
    this.loot = loot;
  }

  public static combine(
    ergebnis1: KampfErgebnis,
    ergebnis2: KampfErgebnis,
    spieler: Spieler
  ): KampfErgebnis {
    const runningResult = new KampfErgebnis(
      ergebnis1.killCount + ergebnis2.killCount,
      ergebnis1.expGained + ergebnis2.expGained,
      ergebnis1.fightPointsNeeded + ergebnis2.fightPointsNeeded,
      ergebnis1.loot
    );
    ItemListeHelper.addLootToInventory(
      ergebnis2.loot.map((item) => item.getPublishedItem()),
      runningResult.loot,
      spieler
    );
    return runningResult;
  }
}
