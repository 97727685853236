import { GegnerListe } from 'src/app/data/gegner-liste';
import { DebugLuck } from 'src/app/services/debug/debug-luck';
import { Gegner } from 'src/app/services/gegner/gegner';
import { Item } from 'src/app/services/items/item';
import {
  AusruestungTyp,
  EquipmentStats,
  ItemId,
  ItemSeltenheit,
  ItemTyp,
} from 'src/app/services/items/item-enums';
import type { Spieler } from 'src/app/services/spieler/spieler';
import type { NonMethods, Optional } from 'src/app/services/type-helper';

export class ItemListe {
  public static readonly ITEMS: Optional<NonMethods<Item>>[] = [
    //#region Rüstungsset Lv. 1
    {
      id: ItemId.HelmLv1,
      name: 'Fellmütze',
      beschreibung:
        'Eine warme Mütze, welches Deine Stirn vor Einschlägen schützt.',
      typ: ItemTyp.Equipment,
      kartenNummer: 1,
      icon: 'level1/eq/helm.png',
      ausruestungTyp: AusruestungTyp.Helm,
      equipmentStats: new EquipmentStats({
        additiveBonus: 1,
      }),
    },

    {
      id: ItemId.RuestungLv1,
      name: 'Fellrüstung',
      beschreibung:
        'Ziemlich stabile Fellrüstung. Schützt Dich etwas vor gegnerischen Angriffen!',
      typ: ItemTyp.Equipment,
      kartenNummer: 1,
      icon: 'level1/eq/ruestung.png',
      ausruestungTyp: AusruestungTyp.Ruestung,
      equipmentStats: new EquipmentStats({
        additiveBonus: 1,
      }),
    },

    {
      id: ItemId.HandschuheLv1,
      name: 'Fellhandschuhe',
      beschreibung:
        'Ziemlich stabile Fellhandschuhe. Schützt Dich etwas vor gegnerischen Angriffen!',
      typ: ItemTyp.Equipment,
      kartenNummer: 1,
      icon: 'level1/eq/handschuhe.png',
      ausruestungTyp: AusruestungTyp.Handschuhe,
      equipmentStats: new EquipmentStats({
        additiveBonus: 1,
      }),
    },

    {
      id: ItemId.SchuheLv1,
      name: 'Fellschuhe',
      beschreibung: 'Ziemlich stabile Fellschuhe. Schützt Deine Füße.',
      typ: ItemTyp.Equipment,
      kartenNummer: 1,
      icon: 'level1/eq/schuhe.png',
      ausruestungTyp: AusruestungTyp.Schuhe,
      equipmentStats: new EquipmentStats({
        additiveBonus: 1,
      }),
    },

    {
      id: ItemId.WaffeLv1,
      name: 'Bärenklaue',
      beschreibung:
        'Selbst gebaute Bärenklaue. Die Krallen schrecken deine Gegner ab.',
      typ: ItemTyp.Equipment,
      kartenNummer: 1,
      icon: 'level1/eq/waffe.png',
      ausruestungTyp: AusruestungTyp.HandRechts,
      equipmentStats: new EquipmentStats({
        additiveBonus: 1,
      }),
    },
    //#endregion

    //#region Schmuckset Lv. 1
    {
      id: ItemId.HalsketteLv1,
      name: 'Zahnhalskette',
      beschreibung: 'Ziemlich schicke Zahnhalskette. Macht dich stärker.',
      typ: ItemTyp.Equipment,
      kartenNummer: 1,
      icon: 'level1/eq/kette.png',
      ausruestungTyp: AusruestungTyp.Halskette,
      equipmentStats: new EquipmentStats({
        multiplicativeBonus: 1.01,
      }),
    },

    {
      id: ItemId.GuertelLv1,
      name: 'Leinengürtel',
      beschreibung: 'Ziemlich cooler Leinengürtel. Macht dich stärker.',
      typ: ItemTyp.Equipment,
      kartenNummer: 1,
      icon: 'level1/eq/guertel.png',
      ausruestungTyp: AusruestungTyp.Guertel,
      equipmentStats: new EquipmentStats({
        multiplicativeBonus: 1.01,
      }),
    },

    {
      id: ItemId.RingLv1,
      name: 'Zahnring',
      beschreibung: 'Ziemlich cooler Zahnring. Macht dich stärker.',
      typ: ItemTyp.Equipment,
      kartenNummer: 1,
      icon: 'level1/eq/ring.png',
      ausruestungTyp: AusruestungTyp.Ring,
      equipmentStats: new EquipmentStats({
        multiplicativeBonus: 1.01,
      }),
    },
    //#endregion

    //#region Crafting Items Lv. 1
    {
      id: ItemId.Craft1Lv1,
      name: 'Fell',
      beschreibung:
        'Ein Stück Fell. Gut geeignet, um eine einfache Rüstung zu bauen.',
      typ: ItemTyp.CraftingItem,
      kartenNummer: 1,
      icon: 'level1/craft/craft1.png',
      stackSize: 10000,
    },

    {
      id: ItemId.Craft2Lv1,
      name: 'Leinen',
      beschreibung:
        'Ein Stück Leinen. Sicherlich gut, um Dinge zusammen zu binden',
      typ: ItemTyp.CraftingItem,
      kartenNummer: 1,
      icon: 'level1/craft/craft2.png',
      stackSize: 10000,
    },

    {
      id: ItemId.Craft3Lv1,
      name: 'Zähne',
      beschreibung: 'Ein Zahn. Daraus kann man Schmuck herstellen.',
      typ: ItemTyp.CraftingItem,
      kartenNummer: 1,
      icon: 'level1/craft/craft3.png',
      stackSize: 10000,
    },

    {
      id: ItemId.Craft4Lv1,
      name: 'Schnur',
      beschreibung: 'Eine Schnur. Daraus kann man Schmuck herstellen.',
      typ: ItemTyp.CraftingItem,
      kartenNummer: 1,
      icon: 'level1/craft/craft4.png',
      stackSize: 10000,
    },
    //#endregion

    //#region Upgrade Items Lv.1
    {
      id: ItemId.Up1,
      name: 'Blaue Essenz',
      beschreibung: 'Magische Essenz um deine Rüstung zu verbessern.',
      typ: ItemTyp.UpgradeItem,
      kartenNummer: 1,
      icon: 'up/up1.png',
      stackSize: 10000,
    },

    {
      id: ItemId.Up2,
      name: 'Lila Essenz',
      beschreibung: 'Magische Essenz um deinen Schmuck zu verbessern.',
      typ: ItemTyp.UpgradeItem,
      kartenNummer: 1,
      icon: 'up/up2.png',
      stackSize: 10000,
    },
    //#endregion

    //#region Nutzbare Items
    {
      id: ItemId.Schmiedehammer,
      name: 'Schmiedehammer',
      beschreibung: 'Ein Hammer um Items zu bearbeiten.',
      typ: ItemTyp.Schmiedebuff,
      kartenNummer: 1,
      icon: 'schmiedehammer.webp',
      stackSize: 10000,
    },

    {
      id: ItemId.PetOrb,
      name: 'Pet-Orb',
      beschreibung: 'Eine magische Kugel um ein Monster gefügig zu machen.',
      typ: ItemTyp.KampfItem,
      kartenNummer: 1,
      icon: 'petorb.webp',
      stackSize: 10000,
      lootTyp: ItemTyp.Equipment,
      loot: ItemListe.generateKampfItemLoot,
    },
    //#endregion

    //#region Pets
    {
      id: ItemId.Pet,
      name: 'Haustier',
      beschreibung: 'Eine magische Kugel um ein Monster gefügig zu machen.',
      typ: ItemTyp.Equipment,
      kartenNummer: 65535, // wird sowieso überschrieben
      icon: 'pet.webp',
      ausruestungTyp: AusruestungTyp.Tier,
      equipmentStats: new EquipmentStats(),
    },
    //#endregion

    //#region Truhen
    ...ItemListe.generateChestDefinitions(),
    //#endregion

    //#region Leere Slots
    ...ItemListe.generateEmptySlotDefinitions(),
    //#endregion

    //#region Entwickleritems
    {
      id: ItemId.EntwicklerSonnenbrille,
      name: 'Sonnenbrille des Entwicklers',
      beschreibung: 'Aktiviert den Dark-Mode überall!',
      seltenheit: ItemSeltenheit.Mythisch,
      kartenNummer: 1337,
      typ: ItemTyp.Equipment,
      icon: 'entwickler/helm.webp',
      ausruestungTyp: AusruestungTyp.Helm,
      equipmentStats: new EquipmentStats({
        additiveBonus: 20,
        multiplicativeBonus: 2,
      }),
    },

    {
      id: ItemId.EntwicklerRubberDuck,
      name: 'Quietsche-Ente des Entwicklers',
      beschreibung: 'Frisst Schnecken. Super um deine Erdbeeren zu beschützen!',
      seltenheit: ItemSeltenheit.Mythisch,
      kartenNummer: 1337,
      typ: ItemTyp.Equipment,
      icon: 'entwickler/ente.webp',
      ausruestungTyp: AusruestungTyp.Umhang,
      equipmentStats: new EquipmentStats({
        additiveBonus: 20,
        multiplicativeBonus: 2,
      }),
    },

    {
      id: ItemId.EntwicklerPoseidonPauke,
      name: 'Pazifik-Pauke',
      beschreibung: 'Reliquie des Poseidon. Verursacht Tsunamis.',
      seltenheit: ItemSeltenheit.Mythisch,
      kartenNummer: 1337,
      typ: ItemTyp.Equipment,
      icon: 'entwickler/pauke.webp',
      ausruestungTyp: AusruestungTyp.HandRechts,
      equipmentStats: new EquipmentStats({
        additiveBonus: 20,
        multiplicativeBonus: 2,
      }),
    },
    //#endregion
  ];

  private constructor() {}

  //#region Basisfunktionen

  public static checkForId(id: string): boolean {
    return ItemListe.ITEMS.some((val) => val.id == id);
  }

  public static getById(id: string): Item {
    return ItemListe.getByIdWithChanges(id);
  }

  public static getByIdWithChanges(
    id: string,
    ...changes: Optional<NonMethods<Item>>[]
  ): Item {
    const itemDefinition = ItemListe.ITEMS.find((val) => val.id == id);
    return new Item(itemDefinition, ...changes);
  }

  public static checkForEquipmentByLevelAndType(
    kartenNummer: number,
    typ: AusruestungTyp
  ): boolean {
    return ItemListe.ITEMS.some(
      (val) => val.kartenNummer == kartenNummer && val.ausruestungTyp == typ
    );
  }

  public static getEquipmentByLevelAndType(
    kartenNummer: number,
    typ: AusruestungTyp,
    ...changes: Optional<NonMethods<Item>>[]
  ): Item {
    const itemDefinition = ItemListe.ITEMS.find(
      (val) => val.kartenNummer == kartenNummer && val.ausruestungTyp == typ
    );
    return new Item(itemDefinition, ...changes);
  }

  //#endregion

  //#region Orbs

  public static generateKampfItemLoot(
    parent?: Item,
    spieler?: Spieler
  ): Item[] {
    if (parent?.typ != ItemTyp.KampfItem || spieler == null) return [];

    switch (parent.lootTyp) {
      case ItemTyp.Equipment:
        return ItemListe.generatePetOrbLoot(spieler);
      case ItemTyp.CraftingItem:
      case ItemTyp.UpgradeItem:
      case ItemTyp.Sonstiges:
      case ItemTyp.Kein:
      case ItemTyp.Truhe:
      case ItemTyp.Schmiedebuff:
      case ItemTyp.KampfItem:
      default:
        console.log('Ungewöhnliches KampfItem', parent);
        break;
    }

    return [];
  }

  public static generatePetOrbLoot(spieler: Spieler): Item[] {
    // 25% Chance, dass man das Monster fängt
    if (DebugLuck.getLuck(1, Math.random(), 0) > 0.25) {
      return [];
    }

    const gegner: Gegner = GegnerListe.getByPlayer(spieler);

    // 1 + [0-4] + 10 * kartenbonus
    const additiveBonus: number =
      1 +
      DebugLuck.getLuck(0, Math.floor(Math.random() * 5), 4) +
      10 * (spieler.kartenNummer - 1);

    return [
      ItemListe.getByIdWithChanges(ItemId.Pet, {
        level: gegner.level,
        gegnerRef: gegner.id,
        equipmentStats: new EquipmentStats({
          additiveBonus: additiveBonus,
        }),
      }),
    ];
  }

  //#endregion

  //#region Truhen

  public static getDailyChest(): Item {
    return ItemListe.getById(ItemId.TruheDaily);
  }

  public static getRandomChestForStreak(
    streak: number,
    typ: ItemTyp,
    ...changes: Optional<NonMethods<Item>>[]
  ): Item {
    const seltenheiten: ItemSeltenheit[] = [
      ItemSeltenheit.Gewoehnlich,
      ItemSeltenheit.Ungewoehnlich,
      ItemSeltenheit.Selten,
      ItemSeltenheit.Episch,
    ];

    const cutoffs: number[] = [0, 1, 4, 7];

    const seltenheit: ItemSeltenheit = ItemListe.draw(
      seltenheiten,
      cutoffs,
      streak
    );

    const itemDefinition: Optional<NonMethods<Item>> | undefined =
      ItemListe.ITEMS.find(
        (val) =>
          val.typ == ItemTyp.Truhe &&
          val.seltenheit == seltenheit &&
          val.lootTyp == typ &&
          val.id != ItemId.TruheDaily
      );
    return new Item(itemDefinition, ...changes);
  }

  public static dailyChallengeChestLoot(
    parent?: Item,
    spieler?: Spieler
  ): Item[] {
    if (spieler == null) return [];

    return GegnerListe.getByPlayer(spieler).generateLoot();
  }

  public static generateChestLoot(parent?: Item, spieler?: Spieler): Item[] {
    if (parent?.typ != ItemTyp.Truhe || spieler == null) return [];

    switch (parent.lootTyp) {
      case ItemTyp.Equipment:
        return ItemListe.generateEquipmentChestLoot(
          spieler.kartenNummer,
          parent.seltenheit
        );
      case ItemTyp.CraftingItem:
        return ItemListe.generateCraftChestLoot(
          spieler.kartenNummer,
          parent.seltenheit
        );
      case ItemTyp.UpgradeItem:
        return ItemListe.generateUpChestLoot(parent.seltenheit);
      case ItemTyp.Sonstiges:
        return ItemListe.generateItemChestLoot(parent.seltenheit);
      case ItemTyp.Kein:
      case ItemTyp.Truhe:
      case ItemTyp.Schmiedebuff:
      case ItemTyp.KampfItem:
      default:
        console.log('Ungewöhnliche Truhe', parent);
        break;
    }

    return [];
  }

  public static generateCraftChestLoot(
    kartenNummer: number,
    seltenheit: ItemSeltenheit
  ): Item[] {
    // countMatrix[seltenheit+groesse][item]
    const countMatrix: number[][] = [
      [1, 1, 1, 1],
      [2, 2, 1, 1],
      [3, 3, 2, 2],
      [4, 4, 2, 2],
      [5, 5, 3, 3],
      [6, 6, 3, 3],
    ];

    return ItemListe.generateLootFromPrefixAndMatrix(
      'Craft',
      countMatrix,
      kartenNummer,
      seltenheit
    );
  }

  public static generateUpChestLoot(seltenheit: ItemSeltenheit): Item[] {
    // countMatrix[seltenheit+groesse][item]
    const countMatrix: number[][] = [
      [2, 2],
      [3, 3],
      [4, 4],
      [5, 5],
      [6, 6],
      [7, 7],
    ];

    return ItemListe.generateLootFromPrefixAndMatrix(
      'Up',
      countMatrix,
      undefined,
      seltenheit
    );
  }

  public static generateEquipmentChestLoot(
    kartenNummer: number,
    seltenheit: ItemSeltenheit
  ): Item[] {
    let typ: AusruestungTyp = ItemListe.rollEquipmentType();
    let i = 0;

    while (
      !ItemListe.checkForEquipmentByLevelAndType(kartenNummer, typ) &&
      i < 100
    ) {
      typ = ItemListe.rollEquipmentType();
      i++;
    }

    if (
      i >= 100 &&
      !ItemListe.checkForEquipmentByLevelAndType(kartenNummer, typ)
    ) {
      return [];
    }

    return [
      ItemListe.getEquipmentByLevelAndType(kartenNummer, typ, {
        seltenheit: ItemListe.rollRarity(seltenheit),
      }),
    ];
  }

  public static generateItemChestLoot(seltenheit: ItemSeltenheit): Item[] {
    if (seltenheit > 3) {
      seltenheit = 3;
    }

    // Cutoffs für Schmiedehammer, Pet-Orb, beides pro Seltenheit
    const chanceTable: number[][] = [
      [40, 80, 100],
      [30, 60, 100],
      [40, 80, 100],
      [30, 60, 100],
    ];

    // In Truhen die mindestens Selten sind ist doppelt so viel drin
    const dropCount = 1 + (seltenheit >= ItemSeltenheit.Selten ? 1 : 0);

    const dropTable: ItemId[][] = [
      [ItemId.Schmiedehammer],
      [ItemId.PetOrb],
      [ItemId.Schmiedehammer, ItemId.PetOrb],
    ];

    const chances: number[] = chanceTable[seltenheit];

    const dropIDs: ItemId[] = ItemListe.roll(dropTable, chances, 100, true);

    const dropItems: Item[] = [];

    for (let i = 0; i < dropIDs.length; i++) {
      dropItems.push(
        ItemListe.getByIdWithChanges(dropIDs[i], { anzahl: dropCount })
      );
    }

    return dropItems;
  }

  public static generateChestDefinitions(): Optional<NonMethods<Item>>[] {
    const result: Optional<NonMethods<Item>>[] = [];

    const seltenheiten: ItemSeltenheit[] = [
      ItemSeltenheit.Gewoehnlich,
      ItemSeltenheit.Ungewoehnlich,
      ItemSeltenheit.Selten,
      ItemSeltenheit.Episch,
    ];

    const seltenheitName: string[] = ['Holz', 'Bronze', 'Silber', 'Gold'];

    const typ: ItemTyp[] = [
      ItemTyp.Equipment,
      ItemTyp.CraftingItem,
      ItemTyp.UpgradeItem,
      ItemTyp.Sonstiges,
    ];

    const typeName: string[] = ['Ausrüstung', 'Material', 'Upgrade', 'Item'];

    const typeNameSafe: string[] = [
      'Ausruestung',
      'Material',
      'Upgrade',
      'Item',
    ];

    const truhenIds: ItemId[] = [
      ItemId.TruheAusruestungHolz,
      ItemId.TruheMaterialHolz,
      ItemId.TruheUpgradeHolz,
      ItemId.TruheItemHolz,

      ItemId.TruheAusruestungBronze,
      ItemId.TruheMaterialBronze,
      ItemId.TruheUpgradeBronze,
      ItemId.TruheItemBronze,

      ItemId.TruheAusruestungSilber,
      ItemId.TruheMaterialSilber,
      ItemId.TruheUpgradeSilber,
      ItemId.TruheItemSilber,

      ItemId.TruheAusruestungGold,
      ItemId.TruheMaterialGold,
      ItemId.TruheUpgradeGold,
      ItemId.TruheItemGold,
    ];

    for (let i = 0; i < seltenheiten.length; i++) {
      for (let j = 0; j < typ.length; j++) {
        result.push({
          id: truhenIds[i * 4 + j],
          name: typeName[j] + ' - ' + seltenheitName[i] + 'truhe',
          icon: 'truhen/truhe' + typeNameSafe[j] + seltenheitName[i] + '.png',
          kartenNummer: 1,
          seltenheit: seltenheiten[i],
          typ: ItemTyp.Truhe,
          lootTyp: typ[j],
          loot: ItemListe.generateChestLoot,
        });
      }
    }

    result.push({
      id: ItemId.TruheDaily,
      name: 'Tagestruhe',
      icon: 'truhen/truheDaily.png',
      beschreibung:
        'Mo: 50 Liegestütze\nDi: 50 Hockstrecksprünge\nMi: 50 Liegestütze\nDo: 50 Hockstrecksprünge\nFr: 50 Liegestütze\nSa: 3 min Plank\nSo: 10 min Dehnen',
      kartenNummer: 1,
      typ: ItemTyp.Truhe,
      lootTyp: ItemTyp.Sonstiges,
      loot: ItemListe.dailyChallengeChestLoot,
    });

    return result;
  }

  //#endregion

  //#region Technische Items

  public static generateEmptySlotDefinitions(): Optional<NonMethods<Item>>[] {
    const typen: AusruestungTyp[] = [
      AusruestungTyp.Helm,
      AusruestungTyp.Ruestung,
      AusruestungTyp.Handschuhe,
      AusruestungTyp.Schuhe,
      AusruestungTyp.Halskette,
      AusruestungTyp.Guertel,
      AusruestungTyp.Ring,
      AusruestungTyp.Umhang,
      AusruestungTyp.HandRechts,
      AusruestungTyp.HandLinks,
      AusruestungTyp.Zusatz,
      AusruestungTyp.Tier,
    ];

    const ids: ItemId[] = [
      ItemId.EmptyEquipSlotHelm,
      ItemId.EmptyEquipSlotRuestung,
      ItemId.EmptyEquipSlotHandschuhe,
      ItemId.EmptyEquipSlotSchuhe,
      ItemId.EmptyEquipSlotHalskette,
      ItemId.EmptyEquipSlotGuertel,
      ItemId.EmptyEquipSlotRing,
      ItemId.EmptyEquipSlotUmhang,
      ItemId.EmptyEquipSlotHandRechts,
      ItemId.EmptyEquipSlotHandLinks,
      ItemId.EmptyEquipSlotZusatz,
      ItemId.EmptyEquipSlotTier,
    ];

    const namen: string[] = [
      'Helm',
      'Rüstung',
      'Handschuhe',
      'Schuhe',
      'Halskette',
      'Gürtel',
      'Ring',
      'Umhang',
      'Rechte Hand',
      'Linke Hand',
      'Zusatz',
      'Tier',
    ];

    const iconNamen: string[] = [
      'helm',
      'ruestung',
      'handschuhe',
      'schuhe',
      'halskette',
      'guertel',
      'ring',
      'umhang',
      'rechte_hand',
      'linke_hand',
      'zusatz',
      'tier',
    ];

    const ret: Optional<NonMethods<Item>>[] = [
      {
        id: ItemId.EmptySlot,
        kartenNummer: 65535,
        seltenheit: ItemSeltenheit.Gewoehnlich,
        beschreibung: 'Ein leerer Slot.',
        name: ' ',
        typ: ItemTyp.Sonstiges,
        icon: 'empty.svg',
      },
      {
        id: ItemId.Missing,
        kartenNummer: 65535,
        seltenheit: ItemSeltenheit.Gewoehnlich,
        beschreibung: 'Hier fehlt was.',
        name: ' ',
        typ: ItemTyp.Sonstiges,
        icon: 'error.png',
      },
      {
        id: ItemId.EmptyHammerSlot,
        kartenNummer: 65535,
        seltenheit: ItemSeltenheit.Gewoehnlich,
        beschreibung: 'Hier kannst du einen Schmiedehammer platzieren.',
        name: ' ',
        typ: ItemTyp.Sonstiges,
        icon: 'item-slots/empty-icons/hammer.png',
      },
      {
        id: ItemId.EmptyDustSlot,
        kartenNummer: 65535,
        seltenheit: ItemSeltenheit.Gewoehnlich,
        beschreibung: 'Hier kannst du Up-Dust platzieren.',
        name: ' ',
        typ: ItemTyp.Sonstiges,
        icon: 'item-slots/empty-icons/up_shadow.png',
      },
    ];

    for (const typ of typen) {
      const index = typen.indexOf(typ);
      ret.push({
        id: ids[index],
        kartenNummer: 65535,
        seltenheit: ItemSeltenheit.Gewoehnlich,
        beschreibung: 'Ein leerer Slot.',
        name: namen[index],
        typ: ItemTyp.Equipment,
        ausruestungTyp: typ,
        equipmentStats: new EquipmentStats(),
        icon: `item-slots/empty-icons/${iconNamen[index]}.png`,
      });
    }

    return ret;
  }

  //#endregion

  //#region Zufall-Helper

  public static generateLootFromPrefixAndMatrix(
    prefix: string,
    matrix: number[][],
    kartenNummer: number | undefined,
    seltenheit: ItemSeltenheit
  ): Item[] {
    if (seltenheit > 3) {
      seltenheit = 3;
    }

    const groesse: number = ItemListe.roll([0, 1, 2], [50, 90, 100], 100, true);

    const chosenCount: number[] = matrix[groesse + seltenheit];

    const result: Item[] = [];

    for (let i = 0; i < chosenCount.length; i++) {
      let id: string = prefix + (i + 1);

      if (kartenNummer != null) {
        id += 'Lv' + kartenNummer;
      }

      result.push(
        ItemListe.getByIdWithChanges(id, {
          anzahl: chosenCount[i],
        })
      );
    }

    return result;
  }

  public static rollRarity(chestRarity: ItemSeltenheit): ItemSeltenheit {
    const seltenheiten: ItemSeltenheit[] = [
      ItemSeltenheit.Gewoehnlich,
      ItemSeltenheit.Ungewoehnlich,
      ItemSeltenheit.Selten,
      ItemSeltenheit.Episch,
      ItemSeltenheit.Legendaer,
    ];

    let chances: number[] = [40, 80, 90, 96, 100];

    switch (chestRarity) {
      case ItemSeltenheit.Gewoehnlich: // Holz
        chances = [70, 90, 96, 99, 100];
        break;
      case ItemSeltenheit.Ungewoehnlich: // Bronze
        chances = [60, 90, 96, 99, 100];
        break;
      case ItemSeltenheit.Selten: // Silber
        chances = [50, 85, 96, 99, 100];
        break;
      case ItemSeltenheit.Episch: // Gold
      case ItemSeltenheit.Legendaer: // ab hier ungenutzt
      case ItemSeltenheit.Mythisch:
      default:
        break;
    }

    return ItemListe.roll(seltenheiten, chances, 100, true);
  }

  public static rollEquipmentType(): AusruestungTyp {
    const typ: AusruestungTyp[] = [
      AusruestungTyp.Helm,
      AusruestungTyp.Handschuhe,
      AusruestungTyp.Schuhe,
      AusruestungTyp.Ruestung,
      AusruestungTyp.HandRechts,
      AusruestungTyp.Ring,
      AusruestungTyp.Guertel,
      AusruestungTyp.Halskette,
      AusruestungTyp.Umhang,
    ];

    let chances: number[] = [10, 20, 30, 40, 50, 66, 82, 98, 100];

    return ItemListe.roll(typ, chances, 100);
  }

  /**
   * Gibt eine anhand der Chancen zufällig gewählte Belohnung zurück.
   * @param reward Mögliche Rückgabewerte
   * @param chances Wahrscheinlichkeiten als Liste von Cutoff-Werten bis zu denen der äquivalente Index aus reward zurückgegeben wird
   * @param maxValue Maximaler Wert in chances
   * @returns Zufälliger Wert aus reward
   */
  public static roll<T>(
    reward: T[],
    chances: number[],
    maxValue: number = 100,
    hasBestResult: boolean = false
  ): T {
    let roll: number = Math.random() * maxValue; // [0-maxValue)

    if (hasBestResult) {
      roll = DebugLuck.getLuck(0, roll, maxValue);
    }

    return ItemListe.draw(reward, chances, roll);
  }

  /**
   * Gibt einen Wert aus reward zurück der durch roll und chances gewählt wird.
   * @param reward Mögliche Rückgabewerte
   * @param chances Liste von Cutoff-Werten bis zu denen der äquivalente Index aus reward zurückgegeben wird
   * @param roll Wert der gegen die Cutoff-Werte abgeglichen wird
   * @returns Wert aus reward
   */
  public static draw<T>(reward: T[], chances: number[], roll: number): T {
    if (reward.length <= 0) {
      throw new Error('Keine Belohnung angegeben!');
    }

    if (reward.length != chances.length) {
      console.warn('Ungewöhnlicher Draw', reward, chances, roll);
    }

    let i = 0;

    // stellt sicher, dass i nicht über Länge hinaus gehen kann
    // nach der Schleife ist i der index des gewählten rewards
    while (i < chances.length - 1 && roll > chances[i]) {
      i++;
    }

    // sicher stellen, dass wir den reward wirklich wählen können
    i = Math.min(i, reward.length - 1);

    return reward[i];
  }

  //#endregion
}
