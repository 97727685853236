import { Biom } from 'src/app/services/gegner/biom';
import type { Gegner } from 'src/app/services/gegner/gegner';
import type { NonMethods, Optional } from 'src/app/services/type-helper';

export class BiomListe {
  public static readonly BIOME: Optional<NonMethods<Biom>>[] = [
    //#region Map 1
    {
      id: 'Strand',
      name: 'Strand',
      hintergrund: 'level1/strand.webp',
    },
    {
      id: 'Dschungel',
      name: 'Dschungel',
      hintergrund: 'level1/dschungel.webp',
    },
    //#endregion

    //#region Map 2
    {
      id: 'Hoehle',
      name: 'Höhle',
      hintergrund: 'level2/hoehle.webp',
    },
    //#endregion
  ];

  private constructor() {}

  public static checkForId(id: string): boolean {
    return BiomListe.BIOME.some((val) => val.id == id);
  }

  public static getById(id: string): Biom {
    return BiomListe.getByIdWithChanges(id);
  }

  public static getByIdWithChanges(
    id: string,
    ...changes: Optional<NonMethods<Biom>>[]
  ): Biom {
    const biomDefinition = BiomListe.BIOME.find((val) => val.id == id);
    return new Biom(biomDefinition, ...changes);
  }

  public static getByEnemy(gegner: Gegner): Biom {
    return BiomListe.getById(gegner.biom);
  }
}
