export enum GegnerTyp {
  Insekt,
  Reptil,
  Saeugetier,
  Vogel,
}

export enum GegnerGruppe {
  Tier,
  Mensch,
  Monster,
  Konstrukt,
  Untot,
}

export enum GegnerElement {
  Feuer,
  Wasser,
  Erde,
  Luft,
  Geist,
}

export enum GegnerId {
  Kein = '',
  Gruener_Frosch = 'Gruener_Frosch',
  Gruenspatz = 'Gruenspatz',
  Sandeidechse = 'Sandeidechse',
  Bunttaube = 'Bunttaube',
  Buntes_Wollhuhn = 'Buntes_Wollhuhn',
  Blaue_Kuestenschildkroete = 'Blaue_Kuestenschildkroete',
  Glutechse = 'Glutechse',
  Goldschnabeladler = 'Goldschnabeladler',
  Gruenleguan = 'Gruenleguan',
}
