import { ItemListe } from 'src/app/data/item-liste';
import { ItemTyp } from 'src/app/services/items/item-enums';
import { ItemReferenz } from 'src/app/services/items/item-referenz';
import { NonMethods, Optional, TypeHelper } from 'src/app/services/type-helper';
import type { Item } from 'src/app/services/items/item';
import type { Spieler } from 'src/app/services/spieler/spieler';

/** Klasse die Definiert, was ein Spieler machen kann */
export class TrackableActivity {
  public id: TrackingId = TrackingId.Kein;
  public name: string = '';
  public typ: TrackedUnit = TrackedUnit.Zeit;
  public pointsPerUnit: number = 0.1;

  public constructor(definition?: Optional<NonMethods<TrackableActivity>>) {
    if (definition != null) {
      // entferne alte Eigenschaften
      definition = TypeHelper.removeUndefinedProperties<
        Optional<NonMethods<TrackableActivity>>
      >({
        id: definition.id,
        name: definition.name,
        pointsPerUnit: definition.pointsPerUnit,
        typ: definition.typ,
      });
    }

    Object.assign(this, definition);
  }
}

/** Klasse die speichert, wie viel der Spieler gemacht hat */
export class TrackedActivity {
  public id: TrackingId = TrackingId.Kein;
  public trackedUnits: number = 0;

  public constructor(definition?: Optional<NonMethods<TrackedActivity>>) {
    if (definition != null) {
      // entferne alte Eigenschaften
      definition = TypeHelper.removeUndefinedProperties<
        Optional<NonMethods<TrackedActivity>>
      >({
        id: definition.id,
        trackedUnits: definition.trackedUnits,
      });
    }

    Object.assign(this, definition);
  }
}

export class TrackedChest {
  public static readonly DAILY_CHEST_ID: string = 'dailyChallengeChest';
  public static readonly COMBI_CHEST_ID: string = 'combiChest';

  public id: string = '';
  public name: string = '';

  /** Die Wochentage, immer 7 Einträge */
  public erfolgreicheTage: (boolean | undefined)[] = [
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
  ];

  public wurdeBearbeitet: boolean = false;
  public streak: number = 0;
  public truhe: ItemReferenz = new ItemReferenz();
  public editierbar: boolean = false;

  public constructor(definition?: Optional<NonMethods<TrackedChest>>) {
    if (definition != null) {
      // entferne alte Eigenschaften
      definition = TypeHelper.removeUndefinedProperties<
        Optional<NonMethods<TrackedChest>>
      >({
        id: definition.id,
        name: definition.name,
        erfolgreicheTage: definition.erfolgreicheTage,
        wurdeBearbeitet: definition.wurdeBearbeitet,
        streak: definition.streak,
        truhe: definition.truhe,
        editierbar: definition.editierbar,
      });
    }

    Object.assign(this, definition);
    this.reinstanciateComplexMembers();
  }

  /**
   * Erstellt alle Subtypen neu, damit eine Kopie des Trackings unabhängige Eigenschaften von einer anderen Kopie dieses Trackings hat.
   */
  private reinstanciateComplexMembers(): void {
    this.erfolgreicheTage ??= [
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
    ];

    while (this.erfolgreicheTage.length < 7) {
      this.erfolgreicheTage.push(undefined);
    }

    if (this.erfolgreicheTage.length > 7) {
      this.erfolgreicheTage = this.erfolgreicheTage.slice(0, 7);
    }

    this.truhe = new ItemReferenz(this.truhe);

    const truheItem: Item = this.truhe.createItem();
    if (truheItem.typ != ItemTyp.Truhe || truheItem.lootTyp == ItemTyp.Kein) {
      const typen: ItemTyp[] = [
        ItemTyp.Equipment,
        ItemTyp.CraftingItem,
        ItemTyp.UpgradeItem,
        ItemTyp.Sonstiges,
      ];
      const typ: ItemTyp = typen[Math.floor(Math.random() * typen.length)];

      if (this.id == TrackedChest.DAILY_CHEST_ID) {
        this.truhe = ItemListe.getDailyChest().getReferenz();
      } else {
        this.truhe = ItemListe.getRandomChestForStreak(
          this.streak,
          typ
        ).getReferenz();
      }
    }
  }

  public canOpen(): boolean {
    const successCount: number = this.erfolgreicheTage.filter(
      (i) => i === true
    ).length;
    const requiredCount: number =
      this.id == TrackedChest.DAILY_CHEST_ID ? 1 : this.erfolgreicheTage.length;

    return !this.wurdeBearbeitet && successCount >= requiredCount;
  }

  public open(target: Spieler, favorit: boolean): Item[] {
    if (this.wurdeBearbeitet) return [];

    this.wurdeBearbeitet = true;

    const successCount: number = this.erfolgreicheTage.filter(
      (val) => val === true
    ).length;

    let count = 1;

    if (this.id == TrackedChest.DAILY_CHEST_ID) {
      count = successCount;
    } else if (successCount < this.erfolgreicheTage.length) {
      count = 0;
    } else if (favorit) {
      count++;
    }

    const truheItem: Item = this.truhe.createItem();
    const ret: Item[] = [];
    for (let i = 0; i < count; i++) {
      const loot: Item[] = truheItem.loot?.(truheItem, target) ?? [];
      ret.push(...loot);
    }
    return ret;
  }

  public advanceWeek(neuerTyp: ItemTyp): void {
    if (this.erfolgreicheTage.every((val) => val === true)) {
      this.streak++;
    } else {
      this.streak = 0;
    }

    this.erfolgreicheTage = [
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
    ];
    this.wurdeBearbeitet = false;

    if (this.id == TrackedChest.DAILY_CHEST_ID || neuerTyp == ItemTyp.Kein) {
      this.streak = 0;
      this.truhe = ItemListe.getDailyChest().getReferenz();
    } else {
      this.truhe = ItemListe.getRandomChestForStreak(
        this.streak,
        neuerTyp
      ).getReferenz();
    }
  }
}

export enum TrackedUnit {
  Anzahl,
  /** in Minuten */
  Zeit,
}

export enum TrackingId {
  Kein = '',
  Fitti = 'fitti',
  Volleyball = 'volleyball',
  Wandern = 'wandern',
  Schritte = 'schritte',
  Tanzen = 'tanzen',
  Golfen = 'golfen',
  Kampfsport = 'kampfsport',
  HitWorkout = 'hit-workout',
  Tennis = 'tennis',
  Work = 'work',
}
