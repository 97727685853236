export class TypeHelper {
  private constructor() {}

  public static removeUndefinedProperties<T extends { [k: string]: unknown }>(
    object: T
  ): T {
    const filteredEntries: [string, unknown][] = Object.entries(object).filter(
      ([_, v]) => v != null
    );
    return Object.fromEntries(filteredEntries) as T;
  }

  public static enumObject<Enum extends Record<string, number | string>>(
    e: Enum
  ): { [K in EnumKeys<Enum>]: Enum[K] } {
    const copy = { ...e } as { [K in EnumKeys<Enum>]: Enum[K] };
    Object.values(e).forEach(
      (value) => typeof value === 'number' && delete copy[value]
    );
    return copy;
  }

  public static enumKeys<Enum extends Record<string, number | string>>(
    e: Enum
  ): EnumKeys<Enum>[] {
    return Object.keys(this.enumObject(e)) as EnumKeys<Enum>[];
  }

  public static enumValues<Enum extends Record<string, number | string>>(
    e: Enum
  ): Enum[EnumKeys<Enum>][] {
    return [
      ...new Set(Object.values(this.enumObject(e))),
    ] as Enum[EnumKeys<Enum>][];
  }

  public static findExtreme<T>(
    list: T[],
    scoreFunction: (item: T) => number,
    maximum: boolean,
    startingScore: number
  ): [T | undefined, number] {
    let bestItem: T | undefined = undefined;
    let bestScore: number = startingScore;

    for (let item of list) {
      const score: number = scoreFunction(item);
      if ((maximum && score > bestScore) || (!maximum && score < bestScore)) {
        bestItem = item;
        bestScore = score;
      }
    }

    return [bestItem, bestScore];
  }

  public static groupBy<T, K>(
    list: T[],
    groupFunction: (item: T) => K
  ): Map<K, T[]> {
    const map: Map<K, T[]> = new Map<K, T[]>();

    list.forEach((item: T) => {
      const key: K = groupFunction(item);
      if (!map.has(key)) {
        map.set(key, [item]);
      } else {
        map.get(key)?.push(item);
      }
    });

    return map;
  }

  /**
   * Erstellt ein Datum in der Woche von {@link input} am {@link targetDay} 12:00:00.000 Uhr.
   * @param input Datum innerhalb der gewünschten Woche (Montag bis Sonntag)
   * @param [targetDay=2] Nullbasierter Zieltag des neuen Datums (0 bis 6 ; Mo bis So ; Mittwoch wenn nicht angegeben)
   */
  public static getDateForWeek(input: Date, targetDay: number = 2): Date {
    const ret = new Date(input);
    ret.setHours(12, 0, 0, 0);

    /** Tag, von Montag bis Sonntag ; 0 - 6 */
    const dayIndex = (ret.getDay() + 6) % 7;
    const diff = targetDay - dayIndex;

    ret.setDate(ret.getDate() + diff);

    return ret;
  }
}

export type OptionalRecursive<T> = {
  [Key in keyof T]?: OptionalRecursive<T[Key]>;
};

export type Optional<T> = {
  [Key in keyof T]?: T[Key];
};

export type NonMethods<T> = {
  [P in keyof T as T[P] extends Function ? never : P]: T[P];
};

export type EnumKeys<Enum> = Exclude<keyof Enum, number>;
