import {
  TrackableActivity,
  TrackedActivity,
  TrackedUnit,
  TrackingId,
} from './tracking-types';
import type { NonMethods, Optional } from 'src/app/services/type-helper';

export class Tracking {
  public static readonly PredefinedActivities: TrackableActivity[] = [
    new TrackableActivity({
      id: TrackingId.Fitti,
      name: 'Fitness-Studio',
      typ: TrackedUnit.Zeit,
      pointsPerUnit: 6 / 60,
    }),
    new TrackableActivity({
      id: TrackingId.Volleyball,
      name: 'Volleyball',
      typ: TrackedUnit.Zeit,
      pointsPerUnit: 3 / 60,
    }),
    new TrackableActivity({
      id: TrackingId.Wandern,
      name: 'Wandern',
      typ: TrackedUnit.Zeit,
      pointsPerUnit: 4 / 60,
    }),
    new TrackableActivity({
      id: TrackingId.Schritte,
      name: 'Schritte',
      typ: TrackedUnit.Anzahl,
      pointsPerUnit: 3 / 10000,
    }),
    new TrackableActivity({
      id: TrackingId.Tanzen,
      name: 'Tanzen',
      typ: TrackedUnit.Zeit,
      pointsPerUnit: 3.5 / 60,
    }),
    new TrackableActivity({
      id: TrackingId.Golfen,
      name: 'Golfen',
      typ: TrackedUnit.Zeit,
      pointsPerUnit: 0.5 / 60,
    }),
    new TrackableActivity({
      id: TrackingId.Kampfsport,
      name: 'Kampfsport',
      typ: TrackedUnit.Zeit,
      pointsPerUnit: 7 / 60,
    }),
    new TrackableActivity({
      id: TrackingId.HitWorkout,
      name: 'HIT Workout',
      typ: TrackedUnit.Zeit,
      pointsPerUnit: 6 / 60,
    }),
    new TrackableActivity({
      id: TrackingId.Tennis,
      name: 'Tennis',
      typ: TrackedUnit.Zeit,
      pointsPerUnit: 4 / 60,
    }),
    new TrackableActivity({
      id: TrackingId.Work,
      name: 'Arbeit',
      typ: TrackedUnit.Zeit,
      pointsPerUnit: 1 / 60,
    }),
  ];

  public static getTrackingPointsFor(activity?: TrackedActivity): number {
    const definition: TrackableActivity | undefined =
      Tracking.PredefinedActivities.find((def) => def.id == activity?.id);
    return (activity?.trackedUnits ?? 0) * (definition?.pointsPerUnit ?? 0);
  }

  public activities: TrackedActivity[] = [];
  public date: Date = new Date();

  constructor(...definition: (Optional<NonMethods<Tracking>> | undefined)[]) {
    Object.assign(this, ...definition);
    this.reinstanciateComplexMembers();
  }

  /**
   * Erstellt alle Subtypen neu, damit eine Kopie des Trackings unabhängige Eigenschaften von einer anderen Kopie dieses Trackings hat.
   */
  private reinstanciateComplexMembers(): void {
    this.date = new Date(this.date);

    this.activities ??= [];

    for (let i = 0; i < this.activities.length; i++) {
      this.activities[i] = new TrackedActivity(this.activities[i]);
    }

    if (typeof (this as any).workTracking != 'undefined') {
      this.activities.push(
        new TrackedActivity({
          id: TrackingId.Work,
          trackedUnits: (this as any).workTracking.trackedUnits,
        })
      );
      delete (this as any).workTracking;
    }
  }

  public getTotalTrackingPoints(): number {
    return this.activities.reduce(
      (soFar, activity) => soFar + Tracking.getTrackingPointsFor(activity),
      0
    );
  }

  public getWorkTrackingPoints(): number {
    return Tracking.getTrackingPointsFor(
      this.activities.find((act) => act.id == TrackingId.Work)
    );
  }

  public getSportTrackingPoints(): number {
    return this.activities.reduce(
      (soFar, activity) =>
        soFar +
        (activity.id == TrackingId.Work
          ? 0
          : Tracking.getTrackingPointsFor(activity)),
      0
    );
  }
}
