export class DebugLuck {
  public static luckModifier: number = 0;

  public static getLuck(
    negativeBorder: number,
    value: number,
    positiveBorder: number
  ): number {
    if (DebugLuck.luckModifier < 0) {
      return value + (value - negativeBorder) * DebugLuck.luckModifier;
    }
    if (DebugLuck.luckModifier > 0) {
      return value + (positiveBorder - value) * DebugLuck.luckModifier;
    }
    return value;
  }
}
