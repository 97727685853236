import { Item } from 'src/app/services/items/item';
import { ItemId, ItemTyp } from 'src/app/services/items/item-enums';
import { ItemReferenz } from 'src/app/services/items/item-referenz';
import { Spieler } from 'src/app/services/spieler/spieler';

export class ItemListeHelper {
  public static hasItem(id: string, inventar: ItemReferenz[]): boolean {
    return inventar.some((val) => val.id == id);
  }

  public static getItemsById(
    id: string,
    inventar: ItemReferenz[]
  ): ItemReferenz[] {
    return inventar.filter((item) => item.id == id);
  }

  public static addLootToInventory(
    items: Item[],
    inventar: ItemReferenz[],
    spieler: Spieler
  ): void {
    for (const item of items) {
      if (item.typ == ItemTyp.Truhe && item.loot != null) {
        // Truhen öffnen und nicht hinzufügen
        // aber Inhalte der Truhe hinzufügen
        this.addLootToInventory(item.loot(item, spieler), inventar, spieler);
      } else {
        this.addItemToInventory(
          item,
          inventar,
          spieler.getNoEquipFightingPoints()
        );
      }
    }
  }

  public static addItemToInventory(
    addition: Item,
    inventar: ItemReferenz[],
    baseDmg: number
  ): void {
    const additionRef: ItemReferenz = addition.getReferenz();
    let lastStackPoint: number = inventar.length;

    if (addition.stackSize > 1) {
      for (let i = 0; i < inventar.length; i++) {
        if (ItemReferenz.stack(inventar[i], additionRef)) {
          lastStackPoint = i + 1;

          if (additionRef.id == ItemId.Kein) {
            break;
          }
        }
      }
    }

    if (additionRef.id != ItemId.Kein) {
      inventar.splice(lastStackPoint, 0, additionRef);
      this.resortInventory(inventar, baseDmg);
    }
  }

  /** Verändert keine Referenzen, sondern sortiert nur! */
  public static resortInventory(
    inventar: ItemReferenz[],
    baseDmg: number
  ): void {
    // Diese Methode darf die bestehenden Referenzen nicht verändern, weil sonst anderer Code mit veralteten Referenzen arbeiten würde!
    // Falls das hier mal zum Performance Problem wird (sehr unwahrscheinlich), dann muss hier eine etwas kompliziertere Sortierfunktion erstellt werden.
    inventar.sort(ItemReferenz.compare.bind(null, baseDmg));
  }

  /** Verändert Referenzen und sorgt für komplette Konsistenz. */
  public static restackInventory(
    inventar: ItemReferenz[],
    baseDmg: number
  ): void {
    this.resortInventory(inventar, baseDmg);

    for (let i = 1; i < inventar.length; i++) {
      const lastItem: ItemReferenz = inventar[i - 1];
      const item: ItemReferenz = inventar[i];

      if (ItemReferenz.stack(lastItem, item) && item.id == ItemId.Kein) {
        inventar.splice(i, 1);
        i--;
      }
    }
  }

  /** Entfernt ein Item vom Typ {@link target}. Nimmt immer das letzte verfügbare Item. */
  public static removeOneOfType(
    target: ItemId,
    inventar: ItemReferenz[]
  ): boolean {
    if (target == ItemId.Kein) {
      return false;
    }

    return this.removeOneFrom(
      this.getItemsById(target, inventar).pop(),
      inventar
    );
  }

  /** Entfernt ein Item aus GENAU dem {@link target}. Sollte nur mit dem letzten Item dieser Art im Inventar aufgerufen werden oder Unstackables. */
  public static removeOneFrom(
    target: ItemReferenz | undefined,
    inventar: ItemReferenz[]
  ): boolean {
    if (target == null) {
      return false;
    }

    const index = inventar.lastIndexOf(target);

    if (index < 0) {
      // Haben target nicht im Inventar
      return false;
    }

    if (
      index + 1 < inventar.length &&
      ItemReferenz.kannStacken(target, inventar[index + 1])
    ) {
      // Ist nicht das letztmögliche Vorkommen / würde Sortierung zerstören
      return false;
    }

    if (target.anzahl == null || target.anzahl <= 1) {
      inventar.splice(index, 1);
      target.destroy();
    } else {
      target.anzahl--;
      target.publishItem();
    }

    return true;
  }

  public static equipItem(
    equipRef: ItemReferenz | undefined,
    ausruestung: ItemReferenz[],
    inventar: ItemReferenz[],
    baseDmg: number
  ): boolean {
    if (equipRef == null) return false;

    const equipItem: Item = equipRef.createItem();

    if (equipItem.typ != ItemTyp.Equipment) return false;

    const index = inventar.indexOf(equipRef);

    if (index < 0) return false;

    inventar.splice(index, 1);

    const current = ausruestung.find(
      (val) => val.getPublishedItem().ausruestungTyp == equipItem.ausruestungTyp
    );
    if (current != null) {
      this.unequipItem(current, ausruestung, inventar, baseDmg);
    }

    ausruestung.push(equipRef);
    return true;
  }

  public static unequipItem(
    equipRef: ItemReferenz | undefined,
    ausruestung: ItemReferenz[],
    inventar: ItemReferenz[],
    baseDmg: number
  ): boolean {
    if (equipRef == null) return false;

    const index = ausruestung.indexOf(equipRef);

    if (index < 0) return false;

    ausruestung.splice(index, 1);
    this.addItemToInventory(equipRef.createItem(), inventar, baseDmg);
    return true;
  }
}
