import { Gegner } from 'src/app/services/gegner/gegner';
import {
  GegnerElement,
  GegnerGruppe,
  GegnerId,
  GegnerTyp,
} from 'src/app/services/gegner/gegner-enums';
import { Item } from 'src/app/services/items/item';
import { ItemListe } from './item-liste';
import { ItemId, ItemTyp } from 'src/app/services/items/item-enums';
import type { NonMethods, Optional } from 'src/app/services/type-helper';
import type { Spieler } from 'src/app/services/spieler/spieler';

export class GegnerListe {
  public static readonly GEGNER: Optional<NonMethods<Gegner>>[] = [
    //#region Map 1
    {
      // Grüner Frosch
      id: GegnerId.Gruener_Frosch,
      name: 'Grüner Frosch',
      beschreibung:
        'Ein kleiner Frosch mit giftiger Haut. Hauptsächlich ernährt sich der Frosch von kleinen Sandfliegen am Strand.',
      kartenNummer: 1,
      feldNummer: 1,
      icon: 'level1/gegner/gruenerfrosch.webp',
      image: 'level1/gegner/gruenerfrosch.webp',
      gruppe: GegnerGruppe.Tier,
      typ: GegnerTyp.Reptil,
      biom: 'Strand',
      level: 1,
      elemente: [GegnerElement.Erde],
      loot: GegnerListe.generateLoot,
    },
    {
      // Grünspatz
      id: GegnerId.Gruenspatz,
      name: 'Grünspatz',
      beschreibung:
        'Ein zierlicher Vogel, welcher sich perfekt im Dschungel tarnt.',
      kartenNummer: 1,
      feldNummer: 2,
      icon: 'level1/gegner/gruenspatz.webp',
      image: 'level1/gegner/gruenspatz.webp',
      gruppe: GegnerGruppe.Tier,
      typ: GegnerTyp.Vogel,
      biom: 'Dschungel',
      level: 2,
      elemente: [GegnerElement.Luft],
      loot: GegnerListe.generateLoot,
    },
    {
      // Sandeidechse
      id: GegnerId.Sandeidechse,
      name: 'Sandeidechse',
      beschreibung:
        'Die Haut des Reptils ist sehr robust und schützt das Tier vor der Hitze der Küste.',
      kartenNummer: 1,
      feldNummer: 3,
      icon: 'level1/gegner/sandeidechse.webp',
      image: 'level1/gegner/sandeidechse.webp',
      gruppe: GegnerGruppe.Tier,
      typ: GegnerTyp.Reptil,
      biom: 'Strand',
      level: 3,
      elemente: [GegnerElement.Erde],
      loot: GegnerListe.generateLoot,
    },
    {
      // Bunttaube
      id: GegnerId.Bunttaube,
      name: 'Bunttaube',
      beschreibung:
        'Der häufig vorkommende Vogel ist ein Allesfresser, welcher meist kleine Insekten vertilgt.',
      kartenNummer: 1,
      feldNummer: 4,
      icon: 'level1/gegner/bunttaube.webp',
      image: 'level1/gegner/bunttaube.webp',
      gruppe: GegnerGruppe.Tier,
      typ: GegnerTyp.Vogel,
      biom: 'Dschungel',
      level: 4,
      elemente: [GegnerElement.Luft],
      loot: GegnerListe.generateLoot,
    },
    {
      // Buntes Wollhuhn
      id: GegnerId.Buntes_Wollhuhn,
      name: 'Buntes Wollhuhn',
      beschreibung:
        'Die Federn des zutraulichen Wollhuhns werden von den Inselbewohnern als heilig angesehen.',
      kartenNummer: 1,
      feldNummer: 5,
      icon: 'level1/gegner/bunteswollhuhn.webp',
      image: 'level1/gegner/bunteswollhuhn.webp',
      gruppe: GegnerGruppe.Tier,
      typ: GegnerTyp.Vogel,
      biom: 'Dschungel',
      level: 5,
      elemente: [GegnerElement.Luft],
      loot: GegnerListe.generateLoot,
    },
    {
      // Blaue Küstenschildkröte
      id: GegnerId.Blaue_Kuestenschildkroete,
      name: 'Blaue Küstenschildkröte',
      beschreibung:
        'Der Panzer des Meeresbewohners ist sehr robust und aus Muschelformen zusammengesetzt.',
      kartenNummer: 1,
      feldNummer: 6,
      icon: 'level1/gegner/blauekuestenschildkroete.webp',
      image: 'level1/gegner/blauekuestenschildkroete.webp',
      gruppe: GegnerGruppe.Tier,
      typ: GegnerTyp.Reptil,
      biom: 'Strand',
      level: 6,
      elemente: [GegnerElement.Erde],
      loot: GegnerListe.generateLoot,
    },
    {
      // Glutechse
      id: GegnerId.Glutechse,
      name: 'Glutechse',
      beschreibung:
        'Der selten vorkommende Strandbewohner ist ein ausgezeichneter Jäger und kann sich in kürzester Zeit im Sand vergraben.',
      kartenNummer: 1,
      feldNummer: 7,
      icon: 'level1/gegner/glutechse.webp',
      image: 'level1/gegner/glutechse.webp',
      gruppe: GegnerGruppe.Tier,
      typ: GegnerTyp.Reptil,
      biom: 'Strand',
      level: 7,
      elemente: [GegnerElement.Erde],
      loot: GegnerListe.generateLoot,
    },
    {
      // Goldschnabeladler
      id: GegnerId.Goldschnabeladler,
      name: 'Goldschnabeladler',
      beschreibung:
        'Der anmutige Raubvogel kann eine Flügelspannweite von über 2 Meter erreichen und gilt als intelligenter Jäger.',
      kartenNummer: 1,
      feldNummer: 8,
      icon: 'level1/gegner/goldschnabeladler.webp',
      image: 'level1/gegner/goldschnabeladler.webp',
      gruppe: GegnerGruppe.Tier,
      typ: GegnerTyp.Vogel,
      biom: 'Dschungel',
      level: 8,
      elemente: [GegnerElement.Luft],
      loot: GegnerListe.generateLoot,
    },
    {
      // Grünleguan
      id: GegnerId.Gruenleguan,
      name: 'Grünleguan',
      beschreibung:
        'Das selten vorkommende Reptil legt seine Eier an der Küste ab und ist sonst kaum zu sehen.',
      kartenNummer: 1,
      feldNummer: 9,
      icon: 'level1/gegner/gruenleguan.webp',
      image: 'level1/gegner/gruenleguan.webp',
      gruppe: GegnerGruppe.Tier,
      typ: GegnerTyp.Reptil,
      biom: 'Strand',
      level: 9,
      elemente: [GegnerElement.Erde],
      loot: GegnerListe.generateLoot,
    },
    //#endregion
  ];

  private constructor() {}

  public static checkForId(id: string): boolean {
    return GegnerListe.GEGNER.some((val) => val.id == id);
  }

  public static getById(id: string): Gegner {
    return GegnerListe.getByIdWithChanges(id);
  }

  public static getByIdWithChanges(
    id: string,
    ...changes: Optional<NonMethods<Gegner>>[]
  ): Gegner {
    const definition = GegnerListe.GEGNER.find((val) => val.id == id);
    return new Gegner(definition, ...changes);
  }

  public static getByPosition(
    kartenNummer: number,
    feldNummer: number
  ): Gegner {
    const definition = GegnerListe.GEGNER.find(
      (val) => val.kartenNummer == kartenNummer && val.feldNummer == feldNummer
    );
    return new Gegner(definition);
  }

  public static getByPlayer(spieler: Spieler): Gegner {
    return GegnerListe.getByPosition(spieler.kartenNummer, spieler.feldNummer);
  }

  public static ensureLoot(inputIds: string[]): Item[] {
    // Kopie erstellen
    const possibleIds = inputIds.slice();

    let id: string = '';
    while (!ItemListe.checkForId(id) && possibleIds.length > 0) {
      id = possibleIds.splice(
        Math.floor(Math.random() * possibleIds.length),
        1
      )[0];
    }

    if (!ItemListe.checkForId(id)) {
      return [];
    }

    return [ItemListe.getById(id)];
  }

  public static generateLoot(gegner: Gegner): Item[] {
    const possibleDropIds: string[] = [
      'Craft1Lv' + gegner.kartenNummer,
      'Craft2Lv' + gegner.kartenNummer,
      'Craft3Lv' + gegner.kartenNummer,
      'Craft4Lv' + gegner.kartenNummer,
      ItemId.Up1,
      ItemId.Up2,
      '__BonusEQ',
      '__MultEQ',
      ItemId.PetOrb,
      ItemId.Schmiedehammer,
    ];

    const dropChances: number[] = [12, 24, 36, 48, 60, 72, 80, 88, 94, 100];

    const bonusEq: string[] = [
      'HelmLv' + gegner.kartenNummer,
      'RuestungLv' + gegner.kartenNummer,
      'ArmschieneLv' + gegner.kartenNummer,
      'SchuheLv' + gegner.kartenNummer,
      'WaffeLv' + gegner.kartenNummer,
    ];

    const multEq: string[] = [
      'HalsketteLv' + gegner.kartenNummer,
      'ArmbandLv' + gegner.kartenNummer,
      'RingLv' + gegner.kartenNummer,
      'UmhangLv' + gegner.kartenNummer,
    ];

    const chosenDrop: string = ItemListe.roll(
      possibleDropIds,
      dropChances,
      100
    );

    if (chosenDrop.startsWith('__')) {
      switch (chosenDrop) {
        case '__BonusEQ':
          return GegnerListe.ensureLoot(bonusEq);
        case '__MultEQ':
          return GegnerListe.ensureLoot(multEq);
        case ItemId.Erfahrung:
          return [
            new Item({
              id: ItemId.Erfahrung,
              name: 'XP',
              icon: 'sonstiges/erfahrung.webp',
              typ: ItemTyp.Truhe,
              lootTyp: ItemTyp.Sonstiges,
              loot: (_, nutzer) => {
                if (nutzer == null) return [];

                nutzer.erfahrungUebrig +=
                  nutzer.getExpRequiredForLevelUp() * 0.1;
                nutzer.updateLevel();

                return [];
              },
            }),
          ];
      }
    }

    return [ItemListe.getById(chosenDrop)];
  }
}
