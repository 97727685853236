import { Spieler } from 'src/app/services/spieler/spieler';
import { NonMethods, Optional, TypeHelper } from 'src/app/services/type-helper';

export class Statistik {
  public woche: Date = new Date();
  public equipmentBonus: number = 0;
  public equipmentMult: number = 1;
  public level: number = 1;
  public erfahrungUebrig: number = 0;
  public kartenNummer: number = 1;
  public feldNummer: number = 1;

  public arbeit: number[] = [];
  public sport: number[] = [];

  public truhen: (boolean | undefined)[][] = [];

  public constructor(definition?: Optional<NonMethods<Statistik>>) {
    if (definition != null) {
      // entferne alte Eigenschaften
      definition = TypeHelper.removeUndefinedProperties<
        Optional<NonMethods<Statistik>>
      >({
        arbeit: definition.arbeit,
        equipmentBonus: definition.equipmentBonus,
        equipmentMult: definition.equipmentMult,
        erfahrungUebrig:
          definition.erfahrungUebrig ?? (definition as any).erfahrung,
        feldNummer: definition.feldNummer,
        kartenNummer: definition.kartenNummer,
        level: definition.level,
        sport: definition.sport,
        truhen: definition.truhen,
        woche: definition.woche,
      });
    }

    Object.assign(this, definition);

    this.woche = TypeHelper.getDateForWeek(new Date(this.woche));
  }

  public static erstelleAusSpieler(spieler: Spieler): Statistik {
    return new Statistik({
      woche: new Date(spieler.aktiveWoche),
      level: spieler.level,
      erfahrungUebrig: spieler.erfahrungUebrig,
      kartenNummer: spieler.kartenNummer,
      feldNummer: spieler.feldNummer,
      equipmentBonus: spieler.getTotalAdditiveBonus(),
      equipmentMult: spieler.getTotalMultiplicativeBonus(),
      arbeit: spieler.activityTracking.map((dayTracking) =>
        dayTracking.getWorkTrackingPoints()
      ),
      sport: spieler.activityTracking.map((dayTracking) =>
        dayTracking.getSportTrackingPoints()
      ),
      truhen: spieler.chestTracking.truhen.map(
        (truhe) => truhe.erfolgreicheTage
      ),
    });
  }

  public erstelleSpielerSnapshot(): Spieler {
    const snapshot = new Spieler({
      aktiveWoche: this.woche,
      level: this.level,
      erfahrungUebrig: this.erfahrungUebrig,
      kartenNummer: this.kartenNummer,
      feldNummer: this.feldNummer,
    });

    snapshot.getTotalAdditiveBonus = () => this.equipmentBonus;
    snapshot.getTotalMultiplicativeBonus = () => this.equipmentMult;
    snapshot.getTotalTrackingPoints = () => {
      const arbeitWoche = this.arbeit.reduce((soFar, val) => soFar + val, 0);
      const sportWoche = this.sport.reduce((soFar, val) => soFar + val, 0);
      return arbeitWoche + sportWoche;
    };

    return snapshot;
  }
}
