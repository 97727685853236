import type { NonMethods, Optional } from 'src/app/services/type-helper';

export enum ItemTyp {
  /** Kein Item, gedacht für leere Slots/Platzhalter */
  Kein = 0,

  /** Ausrüstung (Rüstung, Waffen, Pets usw) */
  Equipment = 1,

  /** Item um Ausrüstung herzustellen */
  CraftingItem = 2,

  /** Item um Ausrüstung zu verbessern */
  UpgradeItem = 3,

  /** Truhe */
  Truhe = 4,

  /** Items die in keine andere Kategorie passen oder für Gruppen von verschiedenen Items */
  Sonstiges = 5,

  /** Item kann in der Schmiede benutzt werden */
  Schmiedebuff = 6,

  /** Item kann in einem Kampf verwendet werden */
  KampfItem = 7,
}

export enum AusruestungTyp {
  Kein = 0,
  Helm = 1,
  Ruestung = 2,
  Handschuhe = 3,
  Halskette = 4,
  Guertel = 5,
  Ring = 6,
  Umhang = 7,
  HandRechts = 8,
  HandLinks = 9,
  Schuhe = 10,
  Zusatz = 11,
  Tier = 12,
}

export enum ItemSeltenheit {
  Gewoehnlich = 0,
  Ungewoehnlich = 1,
  Selten = 2,
  Episch = 3,
  Legendaer = 4,
  Mythisch = 5,
}

export class EquipmentStats {
  public additiveBonus: number = 0;
  public multiplicativeBonus: number = 1;

  public upgradeLevel: number = 0;

  public constructor(...stats: Optional<NonMethods<EquipmentStats>>[]) {
    Object.assign(this, ...stats);
  }
}

export enum ItemId {
  Kein = '',
  EmptySlot = 'empty-slot',
  /** Fehlendes Item oder sonstiger Fehler. */
  Missing = 'missing-item',
  Erfahrung = '__EXP',

  EmptyHammerSlot = 'empty-hammer-slot',
  EmptyDustSlot = 'empty-dust-slot',

  EmptyEquipSlotHelm = 'empty-equip-slot-1',
  EmptyEquipSlotRuestung = 'empty-equip-slot-2',
  EmptyEquipSlotHandschuhe = 'empty-equip-slot-3',
  EmptyEquipSlotHalskette = 'empty-equip-slot-4',
  EmptyEquipSlotGuertel = 'empty-equip-slot-5',
  EmptyEquipSlotRing = 'empty-equip-slot-6',
  EmptyEquipSlotUmhang = 'empty-equip-slot-7',
  EmptyEquipSlotHandRechts = 'empty-equip-slot-8',
  EmptyEquipSlotHandLinks = 'empty-equip-slot-9',
  EmptyEquipSlotSchuhe = 'empty-equip-slot-10',
  EmptyEquipSlotZusatz = 'empty-equip-slot-11',
  EmptyEquipSlotTier = 'empty-equip-slot-12',

  HelmLv1 = 'HelmLv1',
  RuestungLv1 = 'RuestungLv1',
  HandschuheLv1 = 'HandschuheLv1',
  SchuheLv1 = 'SchuheLv1',
  WaffeLv1 = 'WaffeLv1',
  HalsketteLv1 = 'HalsketteLv1',
  GuertelLv1 = 'GuertelLv1',
  RingLv1 = 'RingLv1',

  Craft1Lv1 = 'Craft1Lv1',
  Craft2Lv1 = 'Craft2Lv1',
  Craft3Lv1 = 'Craft3Lv1',
  Craft4Lv1 = 'Craft4Lv1',

  Up1 = 'Up1',
  Up2 = 'Up2',

  Schmiedehammer = 'Schmiedehammer',
  PetOrb = 'PetOrb',
  Pet = 'Pet',

  TruheAusruestungHolz = 'TruheAusruestungHolz',
  TruheMaterialHolz = 'TruheMaterialHolz',
  TruheUpgradeHolz = 'TruheUpgradeHolz',
  TruheItemHolz = 'TruheItemHolz',

  TruheAusruestungBronze = 'TruheAusruestungBronze',
  TruheMaterialBronze = 'TruheMaterialBronze',
  TruheUpgradeBronze = 'TruheUpgradeBronze',
  TruheItemBronze = 'TruheItemBronze',

  TruheAusruestungSilber = 'TruheAusruestungSilber',
  TruheMaterialSilber = 'TruheMaterialSilber',
  TruheUpgradeSilber = 'TruheUpgradeSilber',
  TruheItemSilber = 'TruheItemSilber',

  TruheAusruestungGold = 'TruheAusruestungGold',
  TruheMaterialGold = 'TruheMaterialGold',
  TruheUpgradeGold = 'TruheUpgradeGold',
  TruheItemGold = 'TruheItemGold',

  TruheDaily = 'TruheDaily',

  EntwicklerSonnenbrille = 'EntwicklerSonnenbrille',
  EntwicklerRubberDuck = 'EntwicklerRubberDuck',
  EntwicklerPoseidonPauke = 'EntwicklerPoseidonPauke',
}
